import { useState, useEffect, useMemo } from 'react';
import {
    Alert, AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from "react-toastify";
import { LeaveRequestPeriod, translateLeaveRequestPeriod } from "../../Models/Enums/LeaveRequestPeriod";
import { Col, Row } from 'reactstrap';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LeaveRequestStatus } from '../../Models/Enums/LeaveRequestStatus';
import { LeaveRequest } from '../../Models/LeaveRequest';
import { BalanceCounter } from '../../Models/BalanceCounter';
import { useHistory, useLocation } from 'react-router-dom';
import AbsenceRegistrationService from '../../Services/AbsenceRegistrationService';
import { useMsal } from '@azure/msal-react';
import { convertHoursToTimeString, isDateInPast, convertDatesToTimeString } from '../../Helpers/DateHelper';
import moment from 'moment';
import { WeeksSchedule } from '../../Models/WeeksSchedule';
import { LoadSpinner } from '../../Components/LoadSpinner';
import { DonutChartCard } from '../../Components/AbsenceRegistrationTool/DonutChartCard';
import useWindowHeight from '../../hooks/useWindowHeight';
import ScrollableTimePicker from '../../Components/AbsenceRegistrationTool/ScrollableTimePicker';
import { DaySchedule } from '../../Models/DaySchedule';
import { isFirstWeekActiveOfSchedule } from '../../Helpers/AbsenceRegistrationHelper';
import LeaveCode from '../../Models/LeaveCode';

export default function NewLeaveRequest() {
    const history = useHistory();
    const location = useLocation();
    const states = location.state as any;
    const selectedDate = states.selectedDate as Date;
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBalanceCountersLoading, setIsBalanceCountersLoading] = useState<boolean>(false);
    const [leaveCodes, setLeaveCodes] = useState<LeaveCode[]>([]);
    const [balanceCounters, setBalanceCounters] = useState<BalanceCounter[]>();
    const [weeksSchedules, setWeeksSchedules] = useState<WeeksSchedule[]>([]);
    const [currentWeeksSchedule, setCurrentWeeksSchedule] = useState<WeeksSchedule>();
    const [daySchedule, setDaySchedule] = useState<DaySchedule>();
    const [isConfimationDialogOpen, setIsConfimationDialogOpen] = useState<boolean>(false);
    const [isCancelConfimationDialogOpen, setIsCancelConfimationDialogOpen] = useState<boolean>(false);
    const [period, setPeriod] = useState<LeaveRequestPeriod>(LeaveRequestPeriod.WholeDay);
    const [newEventType, setNewEventType] = useState<string>("");
    const [oldStart, setOldStart] = useState<Date>();
    const [newEventStart, setNewEventStart] = useState<Date>();
    const [newEventEnd, setNewEventEnd] = useState<Date>();
    const absenceRegistrationService = useMemo(() => new AbsenceRegistrationService(instance), [instance]);
    const { windowHeight } = useWindowHeight();
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [selectedDay, setSelectedDay] = useState<number>();
    const [amountOfHours, setAmountOfHours] = useState<string>();
    const [leaveTypeFilter, setLeaveTypeFilter] = useState<string>();
    const today = new Date();

    useEffect(() => {
        let startDate = new Date(selectedDate.valueOf());
        startDate.setHours(8);
        startDate.setMinutes(0);
        setNewEventStart(startDate);

        let endDate = new Date(selectedDate.valueOf());
        endDate.setHours(16);
        endDate.setMinutes(0);
        setNewEventEnd(endDate);

        getBalanceCountersAndWeeksSchedulesByYear(startDate);
    }, []);

    useEffect(() => {
        if (!newEventStart || !newEventEnd)
            return;

        newEventStart.setSeconds(0);
        newEventEnd.setDate(newEventStart.getDate());
        newEventEnd.setMonth(newEventStart.getMonth());
        newEventEnd.setFullYear(newEventStart.getFullYear());
        newEventEnd.setSeconds(0);

        if (period === LeaveRequestPeriod.WholeDay) {
            newEventStart.setHours(8);
            newEventStart.setMinutes(0);
            newEventEnd.setHours(16);
            newEventEnd.setMinutes(0);
            setNewEventStart(new Date(newEventStart));
            setNewEventEnd(new Date(newEventEnd));
        } else if (period === LeaveRequestPeriod.DayPart1) {
            let start = new Date(newEventStart);
            setTimeForDayPart1(start, weeksSchedules);
        } else if (period === LeaveRequestPeriod.DayPart2) {
            let start = new Date(newEventStart);
            setTimeForDayPart2(start, weeksSchedules);
        } else {
            let start = new Date(newEventStart);
            setTimeForHours(start, weeksSchedules);
        }
    }, [period]);

    function calculateHoursBetweenStartAndEnd(start: Date | undefined, end: Date | undefined, currentWeeksSchedule: WeeksSchedule | undefined, selectedDay: number | undefined) {
        if (!currentWeeksSchedule || selectedDay == null || !start || !end)
            return;

        const isFirstWeek = isFirstWeekActiveOfSchedule(start, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];

        let startDate = new Date(start);
        let endDate = new Date(end);

        if (period === LeaveRequestPeriod.WholeDay) {
            const hours = dayHours.hours;
            setAmountOfHours(convertHoursToTimeString(hours));
        } else if (period === LeaveRequestPeriod.DayPart1) {
            const start = parseFloat(dayHours.amStart.replace(",", "."));
            const end = parseFloat(dayHours.amEnd.replace(",", "."));

            startDate.setHours(Math.trunc(start));
            startDate.setMinutes((start % 1) * 100);
            endDate.setHours(Math.trunc(end));
            endDate.setMinutes((end % 1) * 100);

            setAmountOfHours(convertDatesToTimeString(startDate, endDate));
        } else if (period === LeaveRequestPeriod.DayPart2) {
            const start = parseFloat(dayHours.pmStart.replace(",", "."));
            const end = parseFloat(dayHours.pmEnd.replace(",", "."));

            startDate.setHours(Math.trunc(start));
            startDate.setMinutes((start % 1) * 100);
            endDate.setHours(Math.trunc(end));
            endDate.setMinutes((end % 1) * 100);

            setAmountOfHours(convertDatesToTimeString(startDate, endDate));
        } else if (period === LeaveRequestPeriod.Hours) {
            if (dayHours.hasAmTime && dayHours.hasPmTime) {
                const restEnd = parseFloat(dayHours.pmStart.replace(",", "."));
                const restStart = parseFloat(dayHours.amEnd.replace(",", "."));
                let rest = 0;

                const hoursStart = (startDate.getHours() + (startDate.getMinutes() / 100));
                const hoursEnd = (endDate.getHours() + (endDate.getMinutes() / 100));

                if (hoursStart <= restStart && hoursEnd >= restEnd) {
                    rest = restEnd - restStart;
                } else if (hoursStart < restStart && hoursEnd > restStart && hoursEnd < restEnd) {
                    rest = hoursEnd - restStart;
                } else if (hoursStart > restStart && hoursStart < restEnd && hoursEnd > restEnd) {
                    rest = restEnd - hoursStart;
                } else if ((hoursStart <= restStart && hoursEnd <= restStart) || (hoursStart >= restEnd && hoursEnd >= restEnd)) {
                    rest = 0;
                } else {
                    setAmountOfHours("0u 0m");
                    return;
                }

                endDate.setHours(endDate.getHours() - Math.trunc(rest));
                endDate.setMinutes(endDate.getMinutes() - Math.trunc(Math.round((rest % 1) * 100)));
            }

            setAmountOfHours(convertDatesToTimeString(startDate, endDate));
        }
    }

    function getBalanceCountersAndWeeksSchedulesByYear(date: Date) {
        setIsBalanceCountersLoading(true);

        Promise.all([
            absenceRegistrationService.getBalanceCountersByYear(date),
            absenceRegistrationService.getWeeksSchedulesByYear(date),
            absenceRegistrationService.getLeaveCodes()
        ]).then((values: any) => {
            setBalanceCounters(values[0]);
            setLeaveCodes(values[2]);
            setWeeksSchedules(values[1]);
            getCurrentWeeksSchedule(values[1], date);
        }).finally(() => {
            setIsBalanceCountersLoading(false);
        });
    }

    async function retrieveBalanceCountersAndWeeksSchedulesByYear(date: Date) {
        setIsBalanceCountersLoading(true);

        const results = await Promise.all([
            absenceRegistrationService.getBalanceCountersByYear(date),
            absenceRegistrationService.getWeeksSchedulesByYear(date),
        ]).then((values: any) => {
            return values;
        }).finally(() => {
            setIsBalanceCountersLoading(false);
        });

        return results;
    }

    function getCurrentWeeksSchedule(weeksSchedules: WeeksSchedule[], date: Date) {
        const activeWeeksSchedule = weeksSchedules.find(w => {
            const startDate = w.startDate !== null ? new Date(w.startDate) : null;
            const endDate = w.endDate !== null ? new Date(w.endDate) : null;
            return (startDate !== null && startDate <= date) && (endDate === null || endDate > date);
        });

        const selectedDay = getDayOfWeek(date);
        setSelectedDay(selectedDay);
        setCurrentWeeksSchedule(activeWeeksSchedule);

        if (!activeWeeksSchedule)
            return;

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, activeWeeksSchedule);
        const dayHours = isFirstWeek ? activeWeeksSchedule.week1Days[selectedDay] : activeWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);
    }

    function getActiveWeeksSchedule(weeksSchedules: WeeksSchedule[], date: Date) {
        const activeWeeksSchedule = weeksSchedules.find(w => {
            const startDate = w.startDate !== null ? new Date(w.startDate) : null;
            const endDate = w.endDate !== null ? new Date(w.endDate) : null;
            return (startDate !== null && startDate <= date) && (endDate === null || endDate > date);
        });

        return activeWeeksSchedule;
    }

    function getDayOfWeek(date: Date) {
        const day = date.getDay();
        return day === 0 ? 6 : day - 1;
    }

    function setTimeForHours(date: Date, weeksSchedules: WeeksSchedule[]) {
        if (!weeksSchedules || weeksSchedules.length === 0) {
            setNewEventStart(new Date(date));
            return;
        }

        const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, date);
        const selectedDay = getDayOfWeek(date);
        setCurrentWeeksSchedule(currentWeeksSchedule);
        setSelectedDay(selectedDay);

        if (!currentWeeksSchedule) {
            setNewEventStart(new Date(date));
            return;
        }

        let startDate = new Date(date);
        let endDate = new Date(date);

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);

        let startTime = 8;
        if (dayHours.hasAmTime)
            startTime = parseFloat(dayHours.amStart.replace(",", "."));

        let endTime = 8 + (dayHours.hasHours ? convertHoursDecimalToTime(dayHours.hours) : 8);
        if (dayHours.hasPmTime) {
            endTime = parseFloat(dayHours.pmEnd.replace(",", "."));
        } else if (dayHours.hasAmTime) {
            endTime = parseFloat(dayHours.amEnd.replace(",", "."));
        }

        startDate.setHours(Math.trunc(startTime));
        startDate.setMinutes(Math.round((startTime % 1) * 100));
        setStartTime(new Date(startDate));
        setNewEventStart(startDate);

        endDate.setHours(Math.trunc(endTime));
        endDate.setMinutes(Math.round((endTime % 1) * 100));
        endDate.setDate(date.getDate());
        endDate.setMonth(date.getMonth());
        endDate.setFullYear(date.getFullYear());
        setEndTime(new Date(endDate));
        setNewEventEnd(endDate);

        calculateHoursBetweenStartAndEnd(startDate, new Date(endDate), currentWeeksSchedule, selectedDay);
    }

    // 2.5 -> 2u30m
    function convertHoursDecimalToTime(totalHours: number) {
        const hours = Math.trunc(totalHours);
        const minutes = (endTime % 1) * 0.60;
        return (hours + minutes);
    }

    function setTimeForDayPart1(date: Date, weeksSchedules: WeeksSchedule[]) {
        if (!weeksSchedules || weeksSchedules.length === 0) {
            setNewEventStart(new Date(date));
            return;
        }

        const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, date);
        const selectedDay = getDayOfWeek(date);
        setCurrentWeeksSchedule(currentWeeksSchedule);
        setSelectedDay(selectedDay);

        if (!currentWeeksSchedule) {
            setNewEventStart(new Date(date));
            return;
        }

        if (!currentWeeksSchedule.week1Days[selectedDay].hasAmTime) {
            setPeriod(LeaveRequestPeriod.Hours);
            return;
        }

        let startDate = new Date(date);
        let endDate = new Date(date);

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);

        const startTime = parseFloat(dayHours.amStart.replace(",", "."));
        const endTime = parseFloat(dayHours.amEnd.replace(",", "."));

        startDate.setHours(Math.trunc(startTime));
        startDate.setMinutes(Math.round((startTime % 1) * 100));
        setStartTime(new Date(startDate));
        setNewEventStart(startDate);

        endDate.setHours(Math.trunc(endTime));
        endDate.setMinutes(Math.round((endTime % 1) * 100));
        endDate.setDate(date.getDate());
        endDate.setMonth(date.getMonth());
        endDate.setFullYear(date.getFullYear());
        setEndTime(new Date(endDate));
        setNewEventEnd(endDate);

        calculateHoursBetweenStartAndEnd(startDate, new Date(endDate), currentWeeksSchedule, selectedDay);
    }

    function setTimeForDayPart2(date: Date, weeksSchedules: WeeksSchedule[]) {
        if (!weeksSchedules || weeksSchedules.length === 0) {
            setNewEventStart(new Date(date));
            return;
        }

        const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, date);
        const selectedDay = getDayOfWeek(date);
        setCurrentWeeksSchedule(currentWeeksSchedule);
        setSelectedDay(selectedDay);

        if (!currentWeeksSchedule) {
            setNewEventStart(new Date(date));
            return;
        }

        if (!currentWeeksSchedule.week1Days[selectedDay].hasPmTime) {
            setPeriod(LeaveRequestPeriod.Hours);
            return;
        }

        let startDate = new Date(date);
        let endDate = new Date(date);

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);

        const startTime = parseFloat(dayHours.pmStart.replace(",", "."));
        const endTime = parseFloat(dayHours.pmEnd.replace(",", "."));

        startDate.setHours(Math.trunc(startTime));
        startDate.setMinutes(Math.round((startTime % 1) * 100));
        setStartTime(new Date(startDate));
        setNewEventStart(startDate);

        endDate.setHours(Math.trunc(endTime));
        endDate.setMinutes(Math.round((endTime % 1) * 100));
        endDate.setDate(date.getDate());
        endDate.setMonth(date.getMonth());
        endDate.setFullYear(date.getFullYear());
        setEndTime(new Date(endDate));
        setNewEventEnd(endDate);

        calculateHoursBetweenStartAndEnd(startDate, new Date(endDate), currentWeeksSchedule, selectedDay);
    }

    async function handleOnNewEventStartChange(newEventStart: Date) {
        if (!newEventStart || !newEventEnd)
            return;

        let startDate = new Date(newEventStart.valueOf());
        let endDate = new Date(newEventEnd.valueOf());
        let currentWeeksSchedules = weeksSchedules;
        setNewEventStart(startDate);

        if (oldStart?.getFullYear() !== startDate.getFullYear()) {
            setOldStart(startDate);
            const results = await retrieveBalanceCountersAndWeeksSchedulesByYear(startDate);
            setBalanceCounters(results[0]);
            currentWeeksSchedules = results[1];
        }

        if (startDate > newEventEnd) {
            endDate.setDate(startDate.getDate());
            endDate.setMonth(startDate.getMonth());
            endDate.setFullYear(startDate.getFullYear());

            if (period === LeaveRequestPeriod.WholeDay) {
                endDate.setHours(16);
                endDate.setMinutes(0);
                endDate.setSeconds(0);
            }

            setEndTime(moment(endDate));
            setNewEventEnd(endDate);
        }

        if (period === LeaveRequestPeriod.DayPart1) {
            setTimeForDayPart1(startDate, currentWeeksSchedules);
            return;
        } else if (period === LeaveRequestPeriod.DayPart2) {
            setTimeForDayPart2(startDate, currentWeeksSchedules);
            return;
        } else if (period === LeaveRequestPeriod.Hours) {
            setTimeForHours(startDate, currentWeeksSchedules);
            return;
        } else {
            // Whole day
            const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, startDate);
            const selectedDay = getDayOfWeek(startDate);
            setCurrentWeeksSchedule(currentWeeksSchedule);
            setSelectedDay(selectedDay);
        }
    }

    function goBackToAbsenceRegistrationCalender(): void {
        history.push({
            pathname: '/AbsenceRegistrationCalender',
            state: {
                goToDate: new Date(selectedDate.setDate(2))
            }
        });
    }

    function getConfirmationDialog() {
        return (
            <Dialog
                open={isConfimationDialogOpen}
                onClose={() => setIsConfimationDialogOpen(false)}>
                <DialogTitle>Bevestiging</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bent u zeker dat u deze aanvraag wil indienen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="color-blue">
                    <Button onClick={() => setIsConfimationDialogOpen(false)} color="inherit">
                        Nee
                    </Button>
                    <Button onClick={saveNewCalenderEvent} color="inherit" autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function cancelLeaveRequest() {
        setIsCancelConfimationDialogOpen(false)
        goBackToAbsenceRegistrationCalender();
    }

    function getCancelConfirmationDialog() {
        return (
            <Dialog
                open={isCancelConfimationDialogOpen}
                onClose={() => setIsCancelConfimationDialogOpen(false)}>
                <DialogTitle>Annulatie</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bent u zeker dat u deze aanvraag wil annuleren?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="color-blue">
                    <Button onClick={() => setIsCancelConfimationDialogOpen(false)} color="inherit">
                        Nee
                    </Button>
                    <Button onClick={cancelLeaveRequest} color="inherit" autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function saveNewCalenderEvent() {
        if (!newEventStart || !newEventEnd) {
            return;
        }

        newEventStart.setSeconds(0);
        newEventEnd.setSeconds(0);
        newEventStart.setMilliseconds(0);
        newEventEnd.setMilliseconds(0);

        const newEvent: LeaveRequest = {
            id: 0,
            allDay: false,
            showAsWholeDayEvent: false,
            start: newEventStart,
            end: newEventEnd,
            code: newEventType,
            status: LeaveRequestStatus.Pending,
            hours: null,
            period: period,
            totalHours: null,
            colorCode: "",
            remark: "",
            hrLinkStatus: null,
            isSendToPrisma: null,
            primaryLeaveRequestId: null,
            breakTime: null,
            scheduleViaApp: null
        }

        setIsConfimationDialogOpen(false);
        setIsLoading(true);

        absenceRegistrationService.createOrUpdateLeaveRequest(newEvent).then((isSuccess: boolean) => {
            if (isSuccess) {
                toast.success("Uw aanvraag is verzonden!");
                goBackToAbsenceRegistrationCalender();
            }
        }).catch((error: Error) => {
            toast.error(error.message);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    function checkIfFormIsValidAndOpenDialog() {
        if (!balanceCounters || balanceCounters.length === 0) {
            toast.error("Er zijn geen saldotellers ingesteld.");
            return;
        }

        const hasWeekSchedule = weeksSchedules && weeksSchedules.length >= 0 && currentWeeksSchedule;
        if (!hasWeekSchedule) {
            toast.error("Er is geen uurrooster ingesteld voor deze datum");
            return;
        }

        if (!newEventStart || !newEventEnd || newEventType === "") {
            toast.error("Alle verplichte velden moeten ingevuld zijn.");
            return;
        }

        const start = moment(startTime);
        const end = moment(endTime);
        if (period === LeaveRequestPeriod.Hours && (!start || !start.isValid() || !end || !end.isValid())) {
            toast.error("Gelieve een geldige start- en eindtijd in te geven.");
            return;
        }

        if (newEventStart >= newEventEnd) {
            toast.error("De einddatum moet groter zijn als de startdatum.");
            return;
        }

        if (isDateInPast(newEventStart)) {
            toast.error("U kan geen verlofaanvraag in het verleden aanmaken.");
            return;
        }

        setIsConfimationDialogOpen(true);
    }

    function handleStartTimeChange(value: Date) {
        if (value) {
            const startTime = value;
            const start = moment(newEventStart).toDate();
            start.setHours(startTime.getHours());
            start.setMinutes(startTime.getMinutes());
            setNewEventStart(start);

            calculateHoursBetweenStartAndEnd(start, newEventEnd, currentWeeksSchedule, selectedDay);
        }

        setStartTime(value);
    }

    function handleEndTimeChange(value: Date) {
        if (value) {
            const endTime = value;
            const end = moment(newEventEnd).toDate();
            end.setHours(endTime.getHours());
            end.setMinutes(endTime.getMinutes());
            setNewEventEnd(end);

            calculateHoursBetweenStartAndEnd(newEventStart, end, currentWeeksSchedule, selectedDay);
        }

        setEndTime(value);
    }

    function selectBalanceCounter(balanceCounter: BalanceCounter) {
        setLeaveTypeFilter(balanceCounter.type);

        const filteredLeaveCodes = leaveCodes.filter(r => r.leaveTypeCode === balanceCounter.type);

        if (filteredLeaveCodes.length === 1) {
            setNewEventType(filteredLeaveCodes[0].code);
        }
        else if (filteredLeaveCodes.length > 1) {
            setNewEventType("");
        }
    }

    function getLeaveRequestDiv() {
        if (!leaveCodes) {
            return null;
        }

        let filteredLeaveCodes = leaveCodes;
        if (leaveTypeFilter !== undefined) {
            filteredLeaveCodes = filteredLeaveCodes.filter(r => r.leaveTypeCode === leaveTypeFilter);
        }

        const timeFieldsReadOnly = period === LeaveRequestPeriod.DayPart1 || period === LeaveRequestPeriod.DayPart2;

        return (
            <Stack spacing={3} style={{ height: "100%" }} textAlign="start">
                <LoadSpinner top={"65px"} isLoading={isLoading} />
                <FormControl fullWidth className="mt-2">
                    <InputLabel required>Type</InputLabel>
                    <Select
                        label="Type"
                        value={newEventType}
                        onChange={(event: any) => { setNewEventType(event.target.value) }}>
                        <MenuItem value=""></MenuItem>
                        {filteredLeaveCodes
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((leaveCode) => (
                                <MenuItem key={leaveCode.code} value={leaveCode.code}>
                                    {leaveCode.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Periode</InputLabel>
                    <Select
                        label="Periode"
                        value={period}
                        onChange={(event: any) => { setPeriod(event.target.value) }}>
                        <MenuItem value={LeaveRequestPeriod.WholeDay}>{translateLeaveRequestPeriod(LeaveRequestPeriod.WholeDay)}</MenuItem>
                        {currentWeeksSchedule && selectedDay != null && currentWeeksSchedule.week1Days[selectedDay].hasAmTime &&
                            <MenuItem value={LeaveRequestPeriod.DayPart1}>{translateLeaveRequestPeriod(LeaveRequestPeriod.DayPart1)}</MenuItem>
                        }
                        {currentWeeksSchedule && selectedDay != null && currentWeeksSchedule.week1Days[selectedDay].hasPmTime &&
                            <MenuItem value={LeaveRequestPeriod.DayPart2}>{translateLeaveRequestPeriod(LeaveRequestPeriod.DayPart2)}</MenuItem>
                        }
                        <MenuItem value={LeaveRequestPeriod.Hours}>{translateLeaveRequestPeriod(LeaveRequestPeriod.Hours)}</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"nl"}>
                    <>
                        <Row>
                            <Col xs={6} className="p-0 pe-1">
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} fullWidth required />}
                                    minDate={today}
                                    label="Start"
                                    value={newEventStart}
                                    onChange={(newValue) => {
                                        handleOnNewEventStartChange(dayjs(newValue).toDate())
                                    }}
                                />
                            </Col>
                            <Col xs={6} className="p-0 ps-1">
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} fullWidth required />}
                                    minDate={today}
                                    label="Einde"
                                    value={newEventEnd}
                                    onChange={(newValue) => {
                                        setNewEventEnd(dayjs(newValue).toDate());
                                    }}
                                />
                            </Col>
                        </Row>

                        {(period === LeaveRequestPeriod.Hours || period === LeaveRequestPeriod.DayPart1 || period === LeaveRequestPeriod.DayPart2) &&
                            <Row className="mt-2">
                                <Col xs={6} className="p-0 ps-0 pe-1">
                                    <ScrollableTimePicker
                                        value={startTime}
                                        daySchedule={daySchedule}
                                        readOnly={timeFieldsReadOnly}
                                        period={period}
                                        handleUpdate={(value: Date) => handleStartTimeChange(value)}
                                        previousPeriod={LeaveRequestPeriod.WholeDay}
                                    />
                                </Col>
                                <Col xs={6} className="p-0 ps-1 pe-0">
                                    <ScrollableTimePicker
                                        value={endTime}
                                        daySchedule={daySchedule}
                                        readOnly={timeFieldsReadOnly}
                                        period={period}
                                        handleUpdate={(value: Date) => handleEndTimeChange(value)}
                                        previousPeriod={LeaveRequestPeriod.WholeDay}
                                    />
                                </Col>
                            </Row>
                        }
                    </>

                    {period !== LeaveRequestPeriod.WholeDay &&
                        <FormControl fullWidth>
                            <TextField
                                value={amountOfHours}
                                id="outlined-number"
                                label="Aantal uren"
                                type="text"
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    }
                </LocalizationProvider>

                {getBalanceCharts()}
            </Stack>
        );
    }

    function getBalanceCharts() {
        return (
            <div className="mt-4" style={{ paddingBottom: 40 }}>
                <Typography variant="subtitle1" fontWeight="bold" className="mb-1">
                    Saldo
                </Typography>

                <LoadSpinner top={"20px"} isLoading={isBalanceCountersLoading} />

                {balanceCounters &&
                    <Row className="m-0 p-0 row flex-row mb-5">
                        {balanceCounters.length > 0 ?
                            balanceCounters.map((balanceCounter: BalanceCounter) => {
                                return (
                                    <Col xs={6} md={4} key={balanceCounter.id}>
                                        <DonutChartCard
                                            text={balanceCounter.description ?? balanceCounter.typeName}
                                            hoursLeft={balanceCounter.hoursLeft}
                                            totalHours={balanceCounter.totalHours}
                                            color={balanceCounter.colorCode}
                                            isSelected={balanceCounter.type === leaveTypeFilter}
                                            onClickFunction={() => selectBalanceCounter(balanceCounter)}
                                        />
                                    </Col>
                                );
                            })
                            :
                            <Alert severity="error">Geen saldotellers gevonden.</Alert>
                        }
                    </Row>
                }
            </div>
        );
    }

    function getBottomButtonsDiv() {
        return (
            <div style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white",
                zIndex: 1,
                display: "flex",
                justifyContent: "space-evenly",
            }}>

                <IconButton color="inherit" className="color-red"
                    disabled={isBalanceCountersLoading}
                    onClick={() => setIsCancelConfimationDialogOpen(true)} aria-label="Annuleer">
                    <CancelIcon color="inherit" style={{ fontSize: 70 }} />
                </IconButton>
                <IconButton color="inherit" className="color-green"
                    disabled={isLoading || isBalanceCountersLoading}
                    onClick={() => checkIfFormIsValidAndOpenDialog()} aria-label="Bewaar">
                    <CheckCircleIcon color="inherit" style={{ fontSize: 70 }} />
                </IconButton>

            </div>
        );
    }

    function getHeader() {
        return (
            <div>
                <AppBar position="static" elevation={0} className="background-color-blue" style={{ height: "51px" }}>
                    <Toolbar>
                        <Typography variant="h6" sx={{ flexGrow: 1 }} textAlign="start" color="inherit">
                            Nieuwe verlofaanvraag
                        </Typography>
                        <IconButton color="inherit" edge="end" onClick={() => setIsCancelConfimationDialogOpen(true)} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    return (
        <>
            {getHeader()}
            {getConfirmationDialog()}
            {getCancelConfirmationDialog()}

            <div
                className="m-0 pt-3 ps-3 pe-3"
                style={{ height: `calc(${windowHeight}px - 54px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}
            >
                {getLeaveRequestDiv()}
            </div>

            {getBottomButtonsDiv()}
        </>
    );
}
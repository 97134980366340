export enum WorkingTime {
    Fulltime,
    PartTime
}

export function translateWorkingTimeEnumValue(type: WorkingTime): string {
    switch(type) { 
        case WorkingTime.Fulltime: {
           return "Voltijds";
        } 
        case WorkingTime.PartTime: {
            return "Deeltijds";
        } 
        default: { 
            return "";
        } 
    } 
}
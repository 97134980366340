import { useEffect, useState } from "react";

function useWindowHeight() {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        setResizeEventHandlers();
    }, []);

    function setResizeEventHandlers() {
        function handleWindowResize() {
            setWindowHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleWindowResize);
    }

    return { windowHeight };
}

export default useWindowHeight;
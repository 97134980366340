/* eslint-disable react-hooks/exhaustive-deps */
import { Tile } from "../../Components/Tile";
import { HeaderType } from '../../Models/Enums/HeaderType';
import { useHistory } from 'react-router-dom';
import { Button } from "@mui/material";
import { Col, Row, ButtonGroup } from "reactstrap";
import PersonIcon from '@mui/icons-material/Person';
import FamilyIcon from '@mui/icons-material/FamilyRestroom';
import ContractIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import { Header } from "../../Components/Header";

export default function OverviewEmployee() {
    const history = useHistory();

    function goToPage(page: string): void {
        history.push(page);
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0" >
                    <Button variant={"outlined"} className="p-2 buttons-footer background-color-blue" style={{ width: "100%" }} onClick={() => goToPage("/")}>
                        <Row>
                            <Col>
                                <i className="fa-solid fa-arrow-left-long fa-2x"></i>
                            </Col>
                        </Row>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    function getContent() {
        return (
            <div className="pt-2 ps-3 pe-3" style={{height: "calc(100vh - 165px)", overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                <Row className="ps-1 pe-1" >
                    <Col xs={6} md={3}>
                        <div onClick={() => goToPage("/EmployeeGeneral")}>
                            <Tile icon={<PersonIcon fontSize="inherit"/>} description="Algemeen" color="#2F5FA1"/>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div onClick={() => goToPage("/EmployeeFamily")}>
                            <Tile icon={<FamilyIcon fontSize="inherit"/>} description="Gezin" color="#2F5FA1"/>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div onClick={() => goToPage("/EmployeeContract")}>
                            <Tile icon={<ContractIcon fontSize="inherit"/>} description="Contract" color="#674EA7"/>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div onClick={() => goToPage("/EmployeeHours")}>
                            <Tile icon={<CalendarMonthIcon fontSize="inherit"/>} description="Uurrooster" color="#674EA7"/>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div onClick={() => goToPage("/EmployeeWage")}>
                            <Tile icon={<EuroSymbolIcon fontSize="inherit"/>} description="Loon" color="#93C47D"/>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div>
            <Header type={HeaderType.Other} description="Persoonlijke gegevens"/>
            
            {getContent()}
            {getButtonDiv()}
        </div>
    );
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Col, Label, Row } from "reactstrap";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { HeaderType } from '../../Models/Enums/HeaderType';
import { useHistory } from 'react-router-dom';
import EmployeeService from "../../Services/EmployeeService";
import { useForm } from "react-hook-form";
import { LoadSpinner } from "../../Components/LoadSpinner";
import { CarEngineType, translateCarEngineTypeEnumValue } from '../../Models/Enums/CarEngineType';
import { parseTextToDateFormat } from "../../Services/GlobalService";
import { EmployeeWage } from "../../Models/EmployeeWage";
import { translateWagePaymentTermEnumValue, WagePaymentTerm } from '../../Models/Enums/WagePaymentTerm';
import { toast } from "react-toastify";
import { Header } from "../../Components/Header";

export default function EmployeeWagePage() {
    const { instance } = useMsal();
    const history = useHistory();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const [employee, setEmployee] = useState<EmployeeWage>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { register, reset, setValue, getValues } = useForm();

    useEffect(() => {
        getEmployeeContract();
    }, []);

    function getEmployeeContract() {
        setIsLoading(true);

        employeeService.getEmployeeWage().then((employee) => {
            setEmployee(employee);
            reset(employee);
        }).catch((error) => {
            toast.error("Kan geen werknemer vinden.");
            history.push("/");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function goBackToOverviewGeneralPage(): void {
        history.push("/OverviewEmployee");
    }

    function updateCheckboxOfForm(data: any, item: any) {
        setValue(item, data.target.checked);
        // update form values so that fields can be hided when needed
        reset(getValues())
    }

    function updateDateFieldOfForm(data: any, item: string) {
        const empl = getValues() as any;
        empl[item as string] = data.target.value;
        setEmployee(empl);

        setValue(item, data.target.value);
        reset(getValues())
    }

    function getWageDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Loon</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0"> 
                            Loon ({translateWagePaymentTermEnumValue(employee?.wagePaymentTerm as WagePaymentTerm)}):
                        </Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("wage")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Voorschot:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("advance")} className="form-control" readOnly/>
                    </Col>
                </Row>
            </div>
        );
    }

    function getAdvantagesDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Voordelen</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Maaltijdcheques (€):</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("mealVoucher")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Heeft bedrijfswagen?</Label>
                    </Col>
                    <Col xs="12">
                        <input type="checkbox" {...register("hasCar")} onChange={(data) => updateCheckboxOfForm(data, "hasCar")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
            </div>
        );
    }

    function getCarDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Wagen</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Automerk:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("carMake")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Model:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("carModel")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Utilitair voertuig:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="checkbox" {...register("carUtilitair")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">CO2 NEDC:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("carCo2")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">CO2 WLTP:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("carCo2WLPT")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Type motor:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("carEngineType")} className="form-control" disabled>
                            {Object.keys(CarEngineType).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {translateCarEngineTypeEnumValue(CarEngineType[CarEngineType[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Nummerplaat:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("carLicensePlate")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Cataloguswaarde:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("carCatalogValue")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Eerste inschrijving:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.carFirstRegistration as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "carFirstRegistration")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Eigen bijdrage:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("carOwnContribution")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Aan te geven vanaf:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.carDeclareFrom as any)}
                            onChange={(data) => updateDateFieldOfForm(data, "carDeclareFrom")} className="form-control" readOnly/>
                    </Col>
                </Row>
            </div>
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button variant="outlined" style={{ width: "100%" }} className="p-2 buttons-footer background-color-blue" onClick={goBackToOverviewGeneralPage}>
                        <Row>
                            <Col>
                                <i className="fa-solid fa-arrow-left-long fa-2x"></i>
                            </Col>
                        </Row>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <>
            <Header type={HeaderType.Other} description="Loon"/>
            <LoadSpinner top={"150px"} isLoading={isLoading} />

            <div className="ps-3 pe-3" style={{height: "calc(100vh - 165px)", overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {employee ?
                    <div className="p-0 m-0 mt-3 text-start">
                        {getWageDiv()}
                        {getAdvantagesDiv()}
                        {getValues("hasCar") && getCarDiv()}
                    </div>
                : ""}
            </div>;
            {getButtonDiv()}
        </>
    );
}
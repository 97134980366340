/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Col, Label, Row } from "reactstrap";
import { LeaveRequestPeriod, translateLeaveRequestPeriod } from "../../../Models/Enums/LeaveRequestPeriod";
import { LeaveRequestStatus } from '../../../Models/Enums/LeaveRequestStatus';
import { LeaveRequest } from '../../../Models/LeaveRequest';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from "moment";
import { convertHoursDecimalToTimeString } from "../../../Helpers/DateHelper";

interface Props {
    isPopupOpen: boolean;
    setIsPopupOpen: Function;
    leaveRequests: LeaveRequest[];
}

export default function LeaveRequestsNotificationPopup(props: Props) {
    function handleClose() {
        props.setIsPopupOpen(false);
    }

    function getDates(leaveRequest: LeaveRequest): string {
        const start = new Date(leaveRequest.start);
        const end = new Date(leaveRequest.end);

        if (start.getDate() === end.getDate() && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear())
            return moment(start).format("DD/MM/YYYY");

        return `${moment(start).format("DD/MM/YYYY")} - ${moment(end).format("DD/MM/YYYY")}`;
    }

    function getIcon(leaveRequest: LeaveRequest) {
        if (leaveRequest.status === LeaveRequestStatus.Accepted) {
            return (<CheckCircleIcon className="color-green" fontSize="large" />);
        } else if (leaveRequest.status === LeaveRequestStatus.Declined) {
            return (<CancelIcon className="color-red" fontSize="large" />);
        } else if (leaveRequest.status === LeaveRequestStatus.Deleted) {
            return (<DeleteForeverIcon className="color-red" fontSize="large" />);
        }
    }

    function getLeaveRequestNotificationDiv(leaveRequest: LeaveRequest) {
        return (
            <Card className="mt-0" color="primary" style={{ width: "100%" }} sx={{ boxShadow: 2 }}>
                <CardHeader
                    action={
                        <>
                            {getIcon(leaveRequest)}
                        </>
                    }
                    title={
                        <Typography gutterBottom variant="subtitle1" fontWeight="bold" component="div" display="inline-block" margin={0}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}>
                            <div style={{
                                width: 7, height: 17.5, borderRadius: 2.5, display: "inline-block",
                                marginRight: 7.5, top: "2px", position: "relative",
                                opacity: 1,
                                backgroundColor: leaveRequest.colorCode
                            }}></div>
                            {leaveRequest.leaveCodeName}
                        </Typography>
                    }
                    className="pb-0">
                </CardHeader>
                <CardContent className="p-0 p-3 pt-2 pb-2">
                    <Row>
                        <Col xs={6}>
                            <Label className="m-0">
                                {getDates(leaveRequest)}
                            </Label>
                        </Col>
                        <Col xs={6} style={{ textAlign: "end" }}>
                            <Label className="m-0 me-2">
                                {leaveRequest.period === LeaveRequestPeriod.WholeDay ?
                                    translateLeaveRequestPeriod(leaveRequest.period)
                                    :
                                    `${convertHoursDecimalToTimeString(leaveRequest.totalHours as number)}`
                                }
                            </Label>
                        </Col>
                    </Row>
                    {(leaveRequest.status === LeaveRequestStatus.Declined || leaveRequest.status === LeaveRequestStatus.Deleted) &&
                        <Row>
                            <Typography gutterBottom variant="subtitle1" fontWeight="bold" component="div" display="inline-block" paddingBottom={0} marginBottom={0} marginTop={1}>
                                Reden:
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" component="div" display="inline-block" paddingTop={0} margin={0}
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '3',
                                    WebkitBoxOrient: 'vertical',
                                }}>
                                {(leaveRequest.remark && leaveRequest.remark?.trim() !== "") ? leaveRequest.remark : "/"}
                            </Typography>

                        </Row>
                    }
                </CardContent>
            </Card>
        );
    }

    return (
        <Dialog
            open={props.isPopupOpen}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Verlofaanvragen status</DialogTitle>
            <DialogContent dividers={true} style={{ padding: 0 }}>
                {props.leaveRequests.map((leaveRequest: LeaveRequest) => (
                    getLeaveRequestNotificationDiv(leaveRequest)
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
export enum Nationality {
    AD = 102,
    AE = 260,
    AF = 251,
    AG = 403,
    AI = 112,
    AL = 101,
    AM = 249,
    AO = 341,
    AR = 511,
    AS = 402,
    AT = 105,
    AU = 611,
    AZ = 250,
    BA = 149,
    BB = 423,
    BD = 237,
    BE = 150,
    BF = 308,
    BG = 106,
    BH = 268,
    BI = 303,
    BJ = 310,
    BL = 111,
    BM = 112,
    BN = 224,
    BO = 512,
    BR = 513,
    BS = 425,
    BT = 223,
    BW = 302,
    BY = 142,
    BZ = 430,
    CA = 401,
    CD = 306,
    CF = 305,
    CG = 307,
    CH = 127,
    CI = 309,
    CK = 613,
    CL = 514,
    CM = 304,
    CN = 218,
    CO = 515,
    CR = 411,
    CU = 412,
    CV = 339,
    CY = 107,
    CZ = 140,
    DE = 103,
    DJ = 345,
    DK = 108,
    DM = 427,
    DO = 420,
    DZ = 351,
    EC = 516,
    EE = 136,
    EG = 352,
    EH = 354,
    ER = 349,
    ES = 109,
    ET = 311,
    FI = 110,
    FJ = 617,
    FK = 112,
    FM = 602,
    FR = 111,
    GA = 312,
    GB = 112,
    GD = 426,
    GE = 253,
    GF = 111,
    GH = 314,
    GI = 112,
    GL = 108,
    GM = 313,
    GN = 315,
    GP = 111,
    GQ = 337,
    GR = 114,
    GT = 413,
    GU = 402,
    GW = 338,
    GY = 521,
    HK = 238,
    HN = 414,
    HR = 146,
    HT = 419,
    HU = 115,
    ID = 208,
    IE = 116,
    IL = 256,
    IN = 207,
    IQ = 254,
    IR = 255,
    IS = 117,
    IT = 128,
    JM = 415,
    JO = 257,
    JP = 209,
    KE = 336,
    KG = 226,
    KH = 216,
    KI = 607,
    KM = 343,
    KN = 431,
    KP = 219,
    KR = 206,
    KW = 264,
    KY = 112,
    KZ = 225,
    LA = 210,
    LB = 258,
    LC = 428,
    LI = 118,
    LK = 203,
    LR = 318,
    LS = 301,
    LT = 137,
    LU = 113,
    LV = 135,
    LY = 353,
    MA = 354,
    MC = 120,
    MD = 144,
    ME = 151,
    MG = 324,
    MH = 603,
    MK = 154,
    ML = 319,
    MM = 201,
    MN = 221,
    MO = 238,
    MQ = 111,
    MR = 355,
    MS = 112,
    MT = 119,
    MU = 317,
    MV = 222,
    MW = 358,
    MX = 416,
    MY = 212,
    MZ = 340,
    NA = 384,
    NC = 111,
    NE = 321,
    NG = 322,
    NI = 417,
    NL = 129,
    NO = 121,
    NP = 213,
    NR = 615,
    NU = 613,
    NZ = 613,
    OM = 266,
    PA = 418,
    PE = 518,
    PF = 111,
    PG = 619,
    PH = 214,
    PK = 259,
    PL = 122,
    PM = 111,
    PN = 112,
    PR = 402,
    PS = 271,
    PT = 123,
    PW = 679,
    PY = 517,
    QA = 267,
    RE = 111,
    RO = 124,
    RS = 152,
    RU = 145,
    RW = 327,
    SA = 252,
    SB = 623,
    SC = 342,
    SD = 356,
    SE = 126,
    SG = 205,
    SH = 112,
    SI = 147,
    SK = 141,
    SL = 328,
    SM = 125,
    SN = 320,
    SO = 329,
    SR = 522,
    SS = 365,
    ST = 346,
    SV = 421,
    SY = 261,
    SZ = 331,
    TC = 112,
    TD = 333,
    TG = 334,
    TH = 235,
    TJ = 228,
    TK = 613,
    TL = 215,
    TM = 229,
    TN = 357,
    TO = 616,
    TR = 262,
    TT = 422,
    TV = 621,
    TW = 238,
    TZ = 332,
    UA = 143,
    UG = 323,
    US = 402,
    UY = 519,
    UZ = 227,
    VA = 133,
    VC = 429,
    VE = 520,
    VG = 112,
    VI = 402,
    VN = 220,
    VU = 624,
    WF = 111,
    WS = 606,
    XX = 999,
    YE = 270,
    ZA = 325,
    ZM = 335,
    ZW = 344
}
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionType,
    PublicClientApplication,
} from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";
import SignUp from "./Pages/SignUp";
import "./style.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import HomePage from "./Pages/HomePage";
import EmployeeGeneralPage from "./Pages/Employee/EmployeeGeneralPage";
import OverviewEmployee from "./Pages/Employee/OverviewEmployee";
import EmployeeFamilyPage from "./Pages/Employee/EmployeeFamilyPage";
import EmployeeContractPage from "./Pages/Employee/EmployeeContractPage";
import EmployeeHoursPage from "./Pages/Employee/EmployeeHours/EmployeeHoursPage";
import EmployeeWagePage from "./Pages/Employee/EmployeeWagePage";
import { createContext, useEffect } from "react";
import { DrawerSideBar } from "./Components/Drawer/Drawer";
import LocalStorageService from "./Services/LocalStorageService";
import AbsenceRegistrationCalender from "./Pages/AbsenceRegistrationTool/AbsenceRegistrationCalender/AbsenceRegistrationCalender";
import BalanceCounters from "./Pages/AbsenceRegistrationTool/BalanceCounters";
import LeaveRequestReports from "./Pages/AbsenceRegistrationTool/LeaveRequestReports";
import NewLeaveRequest from "./Pages/AbsenceRegistrationTool/NewLeaveRequest";
import DetailsLeaveRequest from "./Pages/AbsenceRegistrationTool/DetailsLeaveRequest";
import { Provider } from "react-redux";
import store from "./store/Store";
import { initPushNotifications, removePushNotificationUserId } from "./Helpers/OneSignalNotificationsHelper";
import Settings from "./Pages/Settings/Settings";
import ManualAddAppToHomeScreen from "./Pages/Settings/ManualAddAppToHomeScreen";

export const DrawerContext = createContext<any>(null);
const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
} else {
    LocalStorageService.clearLoginUser();
    removePushNotificationUserId();
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

function App() {
    useEffect(() => {
        initPushNotifications();
    }, []);

    return (
        <div id="app">
            <AppInsightsContext.Provider value={reactPlugin}>
                <MsalProvider instance={msalInstance}>
                    <Router>
                        <div>
                            <div id="page-wrap" style={{ width: "100%", overflow: "hidden" }}>
                                <Switch>
                                    <Route path="/SignUp">
                                        <SignUp />
                                    </Route>

                                    <MsalAuthenticationTemplate
                                        interactionType={InteractionType.Redirect}
                                        authenticationRequest={loginRequest}
                                    >
                                        <Provider store={store}>
                                            <DrawerSideBar />

                                            <Route exact path="/Home">
                                                <HomePage />
                                            </Route>

                                            {/* Employee */}
                                            <Route exact path="/OverviewEmployee">
                                                <OverviewEmployee />
                                            </Route>
                                            <Route exact path="/EmployeeGeneral">
                                                <EmployeeGeneralPage />
                                            </Route>
                                            <Route exact path="/EmployeeFamily">
                                                <EmployeeFamilyPage />
                                            </Route>
                                            <Route exact path="/EmployeeContract">
                                                <EmployeeContractPage />
                                            </Route>
                                            <Route exact path="/EmployeeHours">
                                                <EmployeeHoursPage />
                                            </Route>
                                            <Route exact path="/EmployeeWage">
                                                <EmployeeWagePage />
                                            </Route>

                                            {/* Absence registration tool */}
                                            <Route exact path="/AbsenceRegistrationCalender">
                                                <AbsenceRegistrationCalender />
                                            </Route>
                                            <Route exact path="/BalanceCounters">
                                                <BalanceCounters />
                                            </Route>
                                            <Route exact path="/LeaveRequestReports">
                                                <LeaveRequestReports />
                                            </Route>
                                            <Route exact path="/NewLeaveRequest">
                                                <NewLeaveRequest />
                                            </Route>
                                            <Route exact path="/DetailsLeaveRequest">
                                                <DetailsLeaveRequest />
                                            </Route>
                                            
                                            {/* Settings */}
                                            <Route exact path="/Settings">
                                                <Settings />
                                            </Route>
                                            <Route exact path="/ManualAddAppToHomeScreen">
                                                <ManualAddAppToHomeScreen />
                                            </Route>

                                            <Route exact path="/">
                                                <HomePage />
                                            </Route>
                                            <Redirect to="/" />
                                        </Provider>
                                    </MsalAuthenticationTemplate>
                                </Switch>
                                <ToastContainer
                                    position="top-center"
                                    autoClose={4000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        </div>
                    </Router>
                </MsalProvider>
            </AppInsightsContext.Provider>
        </div>
    );
}

export default App;

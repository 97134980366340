import { WeeksSchedule } from "../Models/WeeksSchedule";
import { getMondayOfWeek } from "./DateHelper";

export function isFirstWeekActiveOfSchedule(start: Date, currentWeeksSchedule: WeeksSchedule) {
    if (!currentWeeksSchedule || !start)
        return true;

    const isSecondWeekEmpty = IsSecondWeekOfTimeTableEmpty(currentWeeksSchedule);
    if (isSecondWeekEmpty)
        return true;

    const scheduleStartDate = currentWeeksSchedule.startDate as Date;
    const mondayOfWeek = getMondayOfWeek(scheduleStartDate);
    const difference = (start.getTime() - mondayOfWeek.getTime());
    const totalDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    const differenceInWeeks = Math.floor(totalDays / 7);
    const isFirstWeek = differenceInWeeks % 2 === 0;
     
    return isFirstWeek;
}

function IsSecondWeekOfTimeTableEmpty(currentWeeksSchedule: WeeksSchedule) {
    const week2 = currentWeeksSchedule.week2Days;
    return !week2[0].hasHours && !week2[1].hasHours && !week2[2].hasHours && !week2[3].hasHours && !week2[4].hasHours &&
        !week2[5].hasHours && !week2[6].hasHours;
}
export enum Contract {
    Usually,
    FlexiVerbal,
    FlexiWritten,
    FlexiLiable,
    Sportsperson,
    Housekeeper,
    Servant,
    Agriculture,
    Homework,
    HomeworkChildcare,
    Physician,
    PhysicianTraining,
    PhysicianIndependant,
    ApprenticeFlemisch,
    ApprenticeFrench,
    ApprenticeGerman,
    ApprenticeManager,
    ApprenticeIndustrial,
    ApprenticeSocio,
    ApprenticeBio,
    ApprenticeAlternating,
    EarlyRetirement,
    EarlyRetirementPartTime,
    FreeNOSS,
    FreeNOSSManager,
    FreeNOSSOther,
    FreeNOSSSportingEvent,
    FreeNOSSHelper,
    FreeNOSSSocio,
    FreeNOSSEducation,
    FreeNOSSSpecialCultures,
    FreeNOSSVolunteer,
    Horeca,
    HorecaExtraHourLiable,
    HorecaExtraDayLiable,
    HorecaExtraHourForfait,
    HorecaExtraDayForfait,
    HorecaFlexiVerbal,
    HorecaFlexiWritten,
    HorecaFlexiLiable,
    Construction,
    ConstructionAlternating,
    ConstructionApprenticeYounger,
    ConstructionApprentice,
    ConstructionGodfather,
    JobTrainingIBO,
    JobTrainingSchool,
    JobTrainingVDAB,
    JobTrainingLiberalProfession,
    JobTrainingEntry,
    JobTrainingPFIWa,
    JobTrainingABO,
    JobTrainingPFIBx,
    JobTrainingBIO,
    JobTrainingAlternating,
    JobTrainingDisability,
    NonProfitRiziv,
    NonProfitGesco,
    NonProfitDAC,
    NonProfitPrime,
    NonProfitLowSkilled,
    Artist,
    ArtistWithContract,
    ArtistWithoutContract,
    Transport,
    TransportNonMobile,
    TransportGarage,
    Aircrew,
    AircrewPilot,
    AircrewCabinCrew,
    Interim,
    InterimTemporary,
    InterimsPermanent,
    External,
    ExternalApplicant,
    ExternalSubcontractor,
    ExternalAgentIndependant,
    ExternalExtern,
    ExternalIntern,
    ExternalLegalPerson
}

export function translateContractEnumValue(type: Contract): string {
    switch(type) { 
        case Contract.Usually: {
           return "Gewoon";
        } 
        case Contract.FlexiWritten: {
            return "Flexi schriftelijk";
        } 
        case Contract.ApprenticeAlternating: {
            return "Alternerende opleiding";
        } 
        case Contract.FreeNOSSManager: {
            return "Vrij RSZ bedrijfsleider";
        } 
        default: { 
            return "";
        } 
    } 
}
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { VictoryLabel, VictoryPie } from 'victory';
import { convertHoursToTimeString } from '../../Helpers/DateHelper';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
    totalHours: number;
    hoursLeft: number;
    text: string;
    color: string;
    onClickFunction: Function;
    isSelected?: boolean;
}

export function DonutChartCard(props: Props) {
    return (
        <Card 
            className="mb-3" 
            sx={{ boxShadow: 1 }} 
            style={{backgroundColor: props.isSelected ? "rgb(245, 245, 245)" : "", 
                border: props.isSelected ? "3px solid green" : ""}}
        >
            <CardActionArea onClick={() => props.onClickFunction()}>
                <CardContent style={{padding: 0, textAlign: "center"}}>
                    {props.isSelected && 
                        <div style={{position: "absolute", top: "5px", left: "5px"}} className="color-green">
                            <CheckIcon />
                        </div>
                    }
                    

                    <svg viewBox="0 0 210 200" style={{maxWidth: 155}}>
                        <VictoryPie
                            standalone={false}
                            width={210} height={210}
                            colorScale={[props.color, "#e1e2e3"]}
                            data={[
                                { x: 1, y: props.hoursLeft }, { x: 2, y: props.totalHours - props.hoursLeft }
                            ]}
                            cornerRadius={3}
                            innerRadius={60}
                            radius={80}
                            labelRadius={100}
                            style={{ labels: { display: "none" } }}
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            style={{ fontFamily: "Helvetica", fontSize: 21, fontWeight: "bold" }}
                            x={105} y={107.5}
                            text={`${convertHoursToTimeString(props.hoursLeft)}/${props.totalHours}u`}
                        />
                    </svg>
                    <Typography gutterBottom variant="subtitle2" fontWeight="bold" component="div" color={props.color} textAlign="center"
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                        }}>
                        {props.text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
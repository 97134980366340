/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Col, Label, Row } from "reactstrap";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { HeaderType } from '../../Models/Enums/HeaderType';
import { useHistory } from 'react-router-dom';
import EmployeeService from "../../Services/EmployeeService";
import { EmployeeGeneral } from '../../Models/EmployeeGeneral';
import { useForm } from "react-hook-form";
import { LoadSpinner } from "../../Components/LoadSpinner";
import { translateTransportationEnumValue, Transportation } from '../../Models/Enums/Transportation';
import { parseTextToDateFormat } from "../../Services/GlobalService";
import { Gender } from '../../Models/Enums/Gender';
import { Country } from "../../Models/Enums/Country";
import { Language } from '../../Models/Enums/Language';
import { Education, translateEducationEnumValue } from '../../Models/Enums/Education';
import { Nationality } from '../../Models/Enums/Nationality';
import { PayWay, translatePayWayEnumValue } from '../../Models/Enums/PayWay';
import { toast } from "react-toastify";
import { Header } from "../../Components/Header";

export default function EmployeeGeneralPage() {
    const { instance } = useMsal();
    const history = useHistory();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const [employee, setEmployee] = useState<EmployeeGeneral>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { register, reset, setValue, getValues } = useForm();

    useEffect(() => {
        getEmployeeGeneral();
    }, []);

    function getEmployeeGeneral() {
        setIsLoading(true);

        employeeService.getEmployeeGeneral().then((employee) => {
            setEmployee(employee);
            reset(employee);
        }).catch((error) => {
            toast.error("Kan geen werknemer vinden.");
            history.push("/");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function goBackToOverviewGeneralPage(): void {
        history.push("/OverviewEmployee");
    }

    function updateDateFieldOfForm(data: any, item: string) {
        const empl = getValues() as any;
        empl[item as string] = data.target.value;
        setEmployee(empl);

        setValue(item, data.target.value);
        reset(getValues())
    }

    function getPersonalDataDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Persoonsgegevens</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Achternaam:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("lastname")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Voornaam:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("firstname")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Rijksregisternummer:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("insz")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Geboortedatum:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="date" value={parseTextToDateFormat(employee?.birthdate)} 
                            onChange={(data) => updateDateFieldOfForm(data, "birthdate")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Geboorteplaats:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("birthplace")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Geboorteland:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("birthplaceCountry")} className="form-control" disabled>
                            <option></option>
                            {Object.keys(Country).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {Country[key as any]}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Nationaliteit:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("nationality")} className="form-control" disabled>
                            {Object.keys(Nationality).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {Nationality[key as any]}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Geslacht:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("gender")} className="form-control" disabled>
                            {Object.keys(Gender).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {Gender[key as any]}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Invalide:</Label>
                    </Col>
                    <Col xs="3" className="overflow-x">
                        <input type="checkbox" {...register("disabled")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Bankrekeningnummer:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("bankAccountNumber")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Betalingswijze:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("payWay")} className="form-control" disabled>
                            {Object.keys(PayWay).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {translatePayWayEnumValue(PayWay[PayWay[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    function getContactDataDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Contractgegevens</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Straat:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("street")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Huisnummer:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("houseNumber")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Bus:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("houseNumberBox")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Postcode:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("postalCode")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Plaats:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("city")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Land:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("country")} className="form-control" disabled>
                            {Object.keys(Country).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {Country[key as any]}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Km woon/werk:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("distance")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Vervoer:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("transportation")} className="form-control" disabled>
                            {Object.keys(Transportation).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {translateTransportationEnumValue(Transportation[Transportation[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">E-mailadres:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("email")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Telefoon:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <input type="text" {...register("telephone")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Taal:</Label>
                    </Col>
                    <Col xs="12" className="overflow-x">
                        <select {...register("language")} className="form-control" disabled>
                            {Object.keys(Language).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {Language[key as any]}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    function getWorkExperienceDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Werkervaring</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Vorige broepsactiviteit:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("previousProfession")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2 d-flex align-items-center">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Werkzoekende sinds:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.unemployedSince as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "unemployedSince")} className="form-control" readOnly/>
                    </Col>
                </Row>
                {getValues("unemployedSince") !== "" &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Werkervaring info:</Label>
                        </Col>
                        <Col xs="12">
                            <textarea rows={5} {...register("workExperienceInfo")} className="form-control" readOnly>
                            </textarea>
                        </Col>
                    </Row>
                }
            </div>
        );
    }

    function getEducationDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Opleiding</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Opleiding:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("education")} className="form-control" disabled>
                            <option></option>
                            {Object.keys(Education).filter(f => !isNaN(Number(f))).map(key => (
                                <option key={key} value={key}>
                                    {translateEducationEnumValue(Education[Education[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button variant="outlined" style={{ width: "100%" }} className="p-2 buttons-footer background-color-blue" onClick={goBackToOverviewGeneralPage}>
                        <Row>
                            <Col>
                                <i className="fa-solid fa-arrow-left-long fa-2x"></i>
                            </Col>
                        </Row>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <>
            <Header type={HeaderType.Other} description="Algemeen"/>
            <LoadSpinner top={"150px"} isLoading={isLoading} />

            <div className="ps-3 pe-3" style={{height: "calc(100vh - 165px)", overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {employee ?
                    <div className="p-0 m-0 mt-3 text-start">
                        {getPersonalDataDiv()}
                        {getContactDataDiv()}
                        {getWorkExperienceDiv()}
                        {getEducationDiv()}
                    </div>
                : ""}
            </div>;
            {getButtonDiv()}
        </>
    );
}
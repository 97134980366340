import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { Row } from "reactstrap";
import AndroidAddToHomeScreen1 from "../../../Assets/AndroidAddToHomeScreen1.jpg";
import AndroidAddToHomeScreen2 from "../../../Assets/AndroidAddToHomeScreen2.jpg";
import AndroidAddToHomeScreen3 from "../../../Assets/AndroidAddToHomeScreen3.jpg";
import SafariAddToHomeScreen1 from "../../../Assets/SafariAddToHomeScreen1.jpg";
import SafariAddToHomeScreen2 from "../../../Assets/SafariAddToHomeScreen2.jpg";
import SafariAddToHomeScreen3 from "../../../Assets/SafariAddToHomeScreen3.jpg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";

enum Steps {
    Step1,
    Step2,
    Step3
}

interface Props {
    isAndroidSection: boolean;
}

const AddToHomeScreenImages = (props: Props) => {
    const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Step1);
    const [images, setImages] = useState<any[]>();

    useEffect(() => {
        if (props.isAndroidSection) {
            setImages([
                AndroidAddToHomeScreen1, 
                AndroidAddToHomeScreen2, 
                AndroidAddToHomeScreen3
            ]);
        } else {
            setImages([
                SafariAddToHomeScreen1, 
                SafariAddToHomeScreen2, 
                SafariAddToHomeScreen3
            ]);
        }
    }, [props.isAndroidSection]);

    if (!images)
        return null;

    return (
        <div style={{position: "relative"}} className="p-0 m-0">
            <SwipeableViews
                axis={"x"}
                index={selectedStep}
                onChangeIndex={(index: number) => setSelectedStep(index)}
            >
                <div className="pt-2 ps-3 pe-3">
                    <Row
                        className="ps-1 pe-1 justify-content-center"
                    >
                        <img
                            style={{width: "37vh", height: "75vh", borderRadius: "10"}} 
                            src={images[0]} alt="Stap 1 afbeelding" 
                        />
                    </Row>
                </div>
                <div className="pt-2 ps-3 pe-3">
                    <Row
                        className="ps-1 pe-1 justify-content-center"
                    >
                        <img 
                            style={{width: "37vh", height: "75vh"}} 
                            src={images[1]} alt="Stap 2 afbeelding" 
                        />                
                    </Row>
                </div>
                <div className="pt-2 ps-3 pe-3">
                    <Row
                        className="ps-1 pe-1 justify-content-center"
                    >
                        <img 
                            style={{width: "37vh", height: "75vh"}} 
                            src={images[2]} alt="Stap 3 afbeelding" 
                        />
                    </Row>
                </div>
            </SwipeableViews>
            
            <span style={{position: "absolute", left: 0, top: "35vh"}}>
                {selectedStep > 0 && 
                    <Button variant="text" color="inherit" onClick={() => setSelectedStep(index => index > 0 ? index - 1 : 0)}>
                        <ArrowBackIcon color="action" fontSize="medium" />
                    </Button>
                }
            </span>
            <span style={{position: "absolute", right: 0, top: "35vh"}}>
                {selectedStep < 2 &&
                    <Button variant="text" color="inherit" fullWidth onClick={() => setSelectedStep(index => index < 2 ? index + 1 : 2)}>
                        <ArrowForwardIcon color="action" fontSize="medium" />
                    </Button>
                }
            </span>
        </div>
    );
}

export default AddToHomeScreenImages;
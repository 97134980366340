export enum WagePaymentTerm {
    MonthlyWage,
    HourlyWage
}

export function translateWagePaymentTermEnumValue(type: WagePaymentTerm): string {
    switch(type) { 
        case WagePaymentTerm.MonthlyWage: {
           return "per maand";
        } 
        case WagePaymentTerm.HourlyWage: {
            return "per uur";
        } 
        default: { 
            return "";
        } 
    } 
}
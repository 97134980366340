export enum Education {
    Basic,
    LowerSecondary,
    HigherSecondary,
    NotUniversity,
    University,
    Unknown
}

export function translateEducationEnumValue(type: Education): string {
    switch(type) { 
        case Education.Basic: {
           return "Lager onderwijs";
        } 
        case Education.LowerSecondary: {
            return "Lager secundair";
        } 
        case Education.HigherSecondary: {
            return "Hoger secundair";
        } 
        case Education.NotUniversity: {
            return "Hoger niet-universitair onderwijs";
        } 
        case Education.University: {
            return "Universitair onderwijs";
        } 
        case Education.Unknown: {
            return "Onbekend";
        } 
        default: { 
            return "";
        } 
    } 
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import ClbLogo from '../Assets/ClbGroupLogo.png';
import { Label, Row } from "reactstrap";
import { HeaderType } from '../Models/Enums/HeaderType';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { drawerActions } from '../store/DrawerSlicer';

interface Props {
    type: HeaderType;
    description: string | undefined;
}

export function Header(props: Props) {
    const dispatch = useDispatch();

    function toggleDrawer() {
        dispatch(drawerActions.toggle());
    }

    useEffect(() => {
        if (props.type === HeaderType.None)
            return;
    }, [])

    function getHeader() {
        if (props.type === HeaderType.HomePage) {
            return (
                <header className="d-flex flex-wrap justify-content-center header background-color-blue-radial">
                    <Row className="pt-2" style={{ width: "100%" }}>
                        <img src={ClbLogo} alt="CLB Group" style={{ width: "6.5em", height: "2.3em" }} className="mx-auto mb-2" />
                    </Row>
                    <div className="position-absolute top-0 start-0">
                        <IconButton onClick={toggleDrawer} style={{color: "white"}}>
                            <MenuIcon color="inherit" fontSize="large" />
                        </IconButton>
                    </div>
                </header>
            );
        } else if (props.type === HeaderType.Other) {
            return (
                <header className="d-flex flex-wrap justify-content-center header background-color-blue-radial">
                    <Row className="pt-3 pe-2" style={{ width: "100%", textAlign: "right" }}>
                        <Label>{props.description}</Label>
                    </Row>
                    <div className="position-absolute top-0 start-0">
                        <IconButton onClick={toggleDrawer} style={{color: "white"}}>
                            <MenuIcon color="inherit" fontSize="large" />
                        </IconButton>
                    </div>
                </header>
            );
        }
    }

    return (
        <>
            {getHeader()}
        </>
    );
}
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { Tile } from "../Components/Tile";
import { HeaderType } from "../Models/Enums/HeaderType";
import { useHistory } from "react-router";
import { Tab, Tabs } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import AppsIcon from "@mui/icons-material/Apps";
import SwipeableViews from "react-swipeable-views";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Header } from "../Components/Header";
import useWindowHeight from "../hooks/useWindowHeight";
import SettingsIcon from '@mui/icons-material/Settings';

enum TileTab {
    Favorites = 0,
    All,
}

export default function HomePage() {
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState<TileTab>(TileTab.Favorites);
    const { windowHeight } = useWindowHeight();

    // function goToOverviewEmployee(): void {
    //     history.push("/OverviewEmployee");
    // }

    function goToAbsenceRegistrationCalender(): void {
        history.push("/AbsenceRegistrationCalender");
    }

    function goToSettings(): void {
        history.push("/Settings");
    }

    function getTilesDiv() {
        return (
            <SwipeableViews
                axis={"x"}
                index={selectedTab}
                onChangeIndex={(index: number) => setSelectedTab(index)}
            >
                <div className="pt-2 ps-3 pe-3">
                    <Row
                        className="ps-1 pe-1"
                        style={{
                            height: `calc(${windowHeight}px - 140px)`,
                            overflowY: "auto",
                            overflowX: "hidden",
                            maxHeight: "-webkit-fill-available",
                        }}
                    >
                        {/* <Col xs={6} md={3}>
                            <div onClick={goToOverviewEmployee}>
                                <Tile icon={<PersonIcon fontSize="inherit"/>} description="Persoonlijke gegevens" color="#2F5FA1"/>
                            </div>
                        </Col> */}
                        <Col xs={6} md={3}>
                            <div onClick={goToAbsenceRegistrationCalender}>
                                <Tile
                                    icon={<DateRangeIcon fontSize="inherit" />}
                                    description="Verlofaanvraag"
                                    color="var(--blue-color)"
                                />
                            </div>
                        </Col>
                        <Col xs={6} md={3}>
                            <div onClick={goToSettings}>
                                <Tile
                                    icon={<SettingsIcon fontSize="inherit" />}
                                    description="Instellingen"
                                    color="var(--blue-color)"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="pt-2 ps-3 pe-3">
                    <Row
                        className="ps-1 pe-1"
                        style={{
                            height: `calc(${windowHeight}px - 140px)`,
                            overflowY: "auto",
                            overflowX: "hidden",
                            maxHeight: "-webkit-fill-available",
                        }}
                    >
                        {/* <Col xs={6} md={3}>
                            <div onClick={goToOverviewEmployee}>
                                <Tile icon={<PersonIcon fontSize="inherit"/>} description="Persoonlijke gegevens" color="#2F5FA1"/>
                            </div>
                        </Col> */}
                        <Col xs={6} md={3}>
                            <div onClick={goToAbsenceRegistrationCalender}>
                                <Tile
                                    icon={<DateRangeIcon fontSize="inherit" />}
                                    description="Verlofaanvraag"
                                    color="var(--blue-color)"
                                />
                            </div>
                        </Col>
                        <Col xs={6} md={3}>
                            <div onClick={goToSettings}>
                                <Tile
                                    icon={<SettingsIcon fontSize="inherit" />}
                                    description="Instellingen"
                                    color="var(--blue-color)"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </SwipeableViews>
        );
    }

    function getButtonDiv() {
        return (
            <div className="fixed-bottom" style={{ color: "#2F5FA1" }}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    onChange={(event, value) => setSelectedTab(value)}
                >
                    <Tab icon={<StarIcon />} label="Favorieten" />
                    <Tab icon={<AppsIcon />} label="Alle" />
                </Tabs>
            </div>
        );
    }

    return (
        <>
            <Header type={HeaderType.HomePage} description="Home" />

            <div className="pt-2">
                {getTilesDiv()}
                {getButtonDiv()}
            </div>
        </>
    );
}

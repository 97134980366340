export enum Income {
    WithIncome,
    WithoutIncome
}

export function translateIncomeEnumValue(type: Income): string {
    switch(type) { 
        case Income.WithIncome: {
           return "Met inkomsten";
        } 
        case Income.WithoutIncome: {
            return "Geen inkomsten";
        } 
        default: { 
            return "";
        } 
    } 
}
import { useHistory } from "react-router-dom";
import HeaderWithReturnFunction from "../../Components/HeaderWithReturnFunction";
import useWindowHeight from "../../hooks/useWindowHeight";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import AddToHomeScreenOutlinedIcon from '@mui/icons-material/AddToHomeScreenOutlined';
import { registerForPushNotifications } from "../../Helpers/OneSignalNotificationsHelper";
import { useEffect, useState } from "react";
import { isPushNotificationsEnabled } from "../../Helpers/OneSignalNotificationsHelper";
import SettingsItem from "../../Components/Settings/SettingsItem";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const Settings = () => {
    const { windowHeight } = useWindowHeight();
    const history = useHistory();
    const [arePushNotificationsEnabled, setArePushNotificationsEnabled] = useState<boolean>(false);
    const [isPushNotificationsPopupOpen, setIsPushNotificationsPopupOpen] = useState<boolean>(false);

    useEffect(() => {
        checkIfPushNotificationsAreEnabled();
    }, []);

    const checkIfPushNotificationsAreEnabled = () => {
        isPushNotificationsEnabled()
            .then((isEnabled: boolean) => {
                setArePushNotificationsEnabled(isEnabled);
            });
    }

    const getPushNotificationsPopup = () => {
        return (
            <Dialog
                open={isPushNotificationsPopupOpen}
                onClose={() => setIsPushNotificationsPopupOpen(false)}
                scroll="paper"
            >
                <DialogTitle>Push meldingen toestaan</DialogTitle>
                <DialogContent>
                    Opgelet, op iOS (Apple apparaten) moet de app eerst op het beginscherm geplaatst worden voordat u deze functie kunt gebruiken. 
                    <br />
                    Gelieve de applicatie opnieuw op te starten voordat de meldingen in werking gaan.
                    <br />
                    <br />
                    <b>Wilt u de push meldingen toestaan?</b> 
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPushNotificationsPopupOpen(false)}>Annuleer</Button>
                    <Button onClick={closePushNotificationsPopupAndRegister}>Ja</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const closePushNotificationsPopupAndRegister = () => {
        registerForPushNotifications();
        setIsPushNotificationsPopupOpen(false);
    }

    function goBackToHomePage(): void {
        history.push("/");
    }

    function goToManualAddAppToHomeScreen(): void {
        history.push("/ManualAddAppToHomeScreen");
    }

    return (
        <>
            <HeaderWithReturnFunction title={"Instellingen"} returnFunction={goBackToHomePage} />
            {getPushNotificationsPopup()}

            <div className="m-0 pt-3 ps-3 pe-3" 
                style={{height: `calc(${windowHeight}px - 59px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}
            >
                <SettingsItem 
                    description="Push meldingen toestaan"
                    onClickFunction={
                        () => !arePushNotificationsEnabled && setIsPushNotificationsPopupOpen(true)
                    }
                    icon={
                        <NotificationsActiveOutlinedIcon 
                            className={arePushNotificationsEnabled ? "color-gold" : "color-black"} 
                            fontSize="large" 
                        />
                    }
                    isDisabled={arePushNotificationsEnabled}
                />
                <SettingsItem 
                    description="App op beginscherm plaatsen (instructies)"
                    onClickFunction={goToManualAddAppToHomeScreen}
                    icon={<AddToHomeScreenOutlinedIcon fontSize="large" />}
                    isDisabled={false}
                />
            </div>
        </>
    )
}

export default Settings;
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Col, Label, Row } from "reactstrap";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { HeaderType } from '../../Models/Enums/HeaderType';
import { useHistory } from 'react-router-dom';
import EmployeeService from "../../Services/EmployeeService";
import { useForm } from "react-hook-form";
import { LoadSpinner } from "../../Components/LoadSpinner";
import { parseTextToDateFormat } from "../../Services/GlobalService";
import { EmployeeContract } from '../../Models/EmployeeContract';
import { EmploymentStatus, translateEmploymentStatusEnumValue } from '../../Models/Enums/EmploymentStatus';
import { Contract, translateContractEnumValue } from '../../Models/Enums/Contract';
import { ContractType, translateContractTypeEnumValue } from '../../Models/Enums/ContractType';
import { toast } from "react-toastify";
import { Header } from "../../Components/Header";

export default function EmployeeContractPage() {
    const { instance } = useMsal();
    const history = useHistory();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const [employee, setEmployee] = useState<EmployeeContract>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { register, reset, setValue, getValues } = useForm();

    useEffect(() => {
        getEmployeeContract();
    }, []);

    function getEmployeeContract() {
        setIsLoading(true);

        employeeService.getEmployeeContract().then((employee) => {
            setEmployee(employee);
            reset(employee);
        }).catch((error) => {
            toast.error("Kan geen werknemer vinden.");
            history.push("/");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function goBackToOverviewGeneralPage(): void {
        history.push("/OverviewEmployee");
    }

    function updateDataOfForm(data: any, item: any) {
        setValue(item, data.target.value);
        // update form values so that fields can be hided when needed
        reset(getValues())
    }

    function updateCheckboxOfForm(data: any, item: any) {
        setValue(item, data.target.checked);
        // update form values so that fields can be hided when needed
        reset(getValues())
    }

    function updateDateFieldOfForm(data: any, item: string) {
        const empl = getValues() as any;
        empl[item as string] = data.target.value;
        setEmployee(empl);

        setValue(item, data.target.value);
        reset(getValues())
    }

    function getStatusDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Status</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">In dienst:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.dateInService as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "dateInService")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Uit dienst:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.dateOutService as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "dateOutService")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Interim van:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.interimFrom as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "interimFrom")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Interim tot:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.interimTo as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "interimTo")} className="form-control" readOnly/>
                    </Col>
                </Row>
                {getValues("interimTo") !== "" &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Motief instroom</Label>
                        </Col>
                        <Col xs="12">
                            <input type="checkbox" {...register("interimMotive")} 
                                onChange={(data) => updateCheckboxOfForm(data, "interimMotive")} className="checkbox-size form-check-input" disabled/>
                        </Col>
                    </Row>
                }
            </div>
        );
    }

    function getContractDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Contract</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Statuut:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("employmentStatus")} className="form-control" disabled>
                            <option></option>
                            {Object.keys(EmploymentStatus).filter(f => !isNaN(Number(f))).map(key => (
                                <option key={key} value={key}>
                                    {translateEmploymentStatusEnumValue(EmploymentStatus[EmploymentStatus[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Contract:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("contract")} className="form-control" disabled>
                            {Object.keys(Contract).filter(f => !isNaN(Number(f))).map(key => (
                                <>
                                    {(key === "0" || key === "2" || key === "20" || key === "24") &&
                                        <option key={key} value={key}>
                                            {translateContractEnumValue(Contract[Contract[key as any] as any] as any)}
                                        </option>
                                    }
                                </>
                                
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Student:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="checkbox" {...register("student")} onChange={(data) => updateCheckboxOfForm(data, "student")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
                {!getValues("student") &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Soort contract:</Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("contractType")} onChange={(data) => updateDataOfForm(data, "contractType")} className="form-control" disabled>
                                {Object.keys(ContractType).filter(f => !isNaN(Number(f))).map(key => (
                                    <option key={key} value={key}>
                                        {translateContractTypeEnumValue(ContractType[ContractType[key as any] as any] as any)}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                }
                {!getValues("student") && (getValues("contractType") == ContractType.FixedTermContract || getValues("contractType") == ContractType.ReplacementContract) &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Einddatum contract:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="date" value={parseTextToDateFormat(employee?.endFixedTerm as any)} onChange={(data) => updateDateFieldOfForm(data, "endFixedTerm")} 
                                className="form-control" readOnly/>
                        </Col>
                    </Row>
                }
                {!getValues("student") && getValues("contractType") == ContractType.ReplacementContract &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Reden vervanging:</Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("replacementReason")} onChange={(data) => updateDataOfForm(data, "replacementReason")} className="form-control" disabled>
                                {employee?.replacementReasonList?.map(replacementReason => (
                                    <option key={replacementReason.code} value={replacementReason.code}>
                                        {replacementReason.name}
                                    </option>
                                ))}
                            </select>                        
                        </Col>
                    </Row>
                }
                {!getValues("student") && getValues("contractType") == ContractType.ReplacementContract &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Te vervangen persoon:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("replacedPerson")} className="form-control" readOnly/>                
                        </Col>
                    </Row>
                }
                {!getValues("student") && getValues("contractType") == ContractType.CertainWork &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Omschrijving bepaald werk:</Label>
                        </Col>
                        <Col xs="12">
                            <input type="text" {...register("certainWorkDescription")} className="form-control" readOnly/>                
                        </Col>
                    </Row>
                }
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Paritair Comité:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("parCom")} className="form-control" disabled>
                            {employee?.parComList?.map(parCom => (
                                <option key={parCom.code}>
                                    {parCom.name}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Functieomschrijving:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("functionDescription")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Beroepscategorie:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("proffCat")} className="form-control" disabled>
                            {employee?.proffCatList?.map(proffCat => (
                                <option key={proffCat.code as any} value={proffCat.code as any}>
                                    {proffCat.name}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Activiteit tov risico:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("risk")} className="form-control" disabled>
                            {employee?.riskList?.map(risk => (
                                <option key={risk.id as any} value={risk.id as any}>
                                    {risk.name}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button variant="outlined" style={{ width: "100%" }} className="p-2 buttons-footer background-color-blue" onClick={goBackToOverviewGeneralPage}>
                        <Row>
                            <Col>
                                <i className="fa-solid fa-arrow-left-long fa-2x"></i>
                            </Col>
                        </Row>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <>
            <Header type={HeaderType.Other} description="Contract"/>
            <LoadSpinner top={"150px"} isLoading={isLoading} />

            <div className="ps-3 pe-3" style={{height: "calc(100vh - 165px)", overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {employee ?
                    <div className="p-0 m-0 mt-3 text-start">
                        {getStatusDiv()}
                        {getContractDiv()}
                    </div>
                : ""}
            </div>;
            {getButtonDiv()}
        </>
    );
}
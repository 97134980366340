import { memo, useEffect } from "react";

interface ToolbarProps {
    toolbar: any;
    setDateLabel: Function;
    dateLabel: string
}

const CustomToolbar = memo((props: ToolbarProps) => {
    useEffect(() => {
        if (props.dateLabel !== props.toolbar.label)
            props.setDateLabel(props.toolbar.label);
    }, [props]);
    
    return null;
});

export default CustomToolbar;
import { LoginUser } from "../Models/LoginUser";

const LocalStorageService = {
    getLoginUser(): LoginUser {
        const stored = localStorage.getItem("loginUser");
        if (!stored) {
            return null as any;
        }
        return JSON.parse(stored);
    },
    setLoginUser(data: LoginUser) {
        if (!data)
            return;
        const json = JSON.stringify(data);
        localStorage.setItem("loginUser", json);
    },
    clearLoginUser() {
        const json = JSON.stringify(null);
        localStorage.setItem("loginUser", json);
    }
}

export default LocalStorageService;
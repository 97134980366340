import { useState } from "react";
import "./TimeTable.css";
import { Button, ButtonGroup, Col, Label, Row, Table } from "reactstrap";
import { TwoWeeklySchedule } from '../../../../Models/TwoWeeklySchedule';

interface Props {
    timeTable: TwoWeeklySchedule;
}

export default function TimeTable(props: Props) {
    const [timeTable] = useState<TwoWeeklySchedule>(props.timeTable);
    const [selectedTimeTableIndex, setSelectedTimeTableIndex] = useState<number>(0);

    function nextTimeTable(): void {
        if (selectedTimeTableIndex < 2) {
            setSelectedTimeTableIndex(selectedTimeTableIndex + 1);
        }
    }

    function previousTimeTable(): void {
        if (selectedTimeTableIndex > 0) {
            setSelectedTimeTableIndex(selectedTimeTableIndex - 1);
        }
    }

    function setTimeTableFromDropDown(event: any): void {
        const index = Number.parseInt(event.target.value);
        setSelectedTimeTableIndex(index);
    }

    function getTimeTableWeek1() {
        return (
            <>      
                <Table className="timetable-table">
                    <thead>
                        <tr>
                            <th style={{width: "80px", textAlign: "center"}}>{1}</th>
                            <th style={{width: "80px", textAlign: "center"}}>Uren</th>
                            <th style={{width: "110px", textAlign: "center"}}>VM</th>
                            <th style={{width: "110px", textAlign: "center"}}>NM</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Ma</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1MondayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1MondayAMStart}-{timeTable?.week1MondayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1MondayPMStart}-{timeTable?.week1MondayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Di</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1TuesdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1TuesdayAMStart}-{timeTable?.week1TuesdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1TuesdayPMStart}-{timeTable?.week1TuesdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Wo</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1WednesdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1WednesdayAMStart}-{timeTable?.week1WednesdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1WednesdayPMStart}-{timeTable?.week1WednesdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Do</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1ThursdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1ThursdayAMStart}-{timeTable?.week1ThursdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1ThursdayPMStart}-{timeTable?.week1ThursdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Vr</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1FridayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1FridayAMStart}-{timeTable?.week1FridayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1FridayPMStart}-{timeTable?.week1FridayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Za</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1SaturdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1SaturdayAMStart}-{timeTable?.week1SaturdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1SaturdayPMStart}-{timeTable?.week1SaturdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Zo</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1SundayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1SundayAMStart}-{timeTable?.week1SundayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week1SundayPMStart}-{timeTable?.week1SundayPMEnd}</b></Label>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    }

    function getTimeTableWeek2() {
        return (
            <>      
                <Table className="timetable-table">
                    <thead>
                        <tr>
                            <th style={{width: "80px", textAlign: "center"}}>{2}</th>
                            <th style={{width: "80px", textAlign: "center"}}>Uren</th>
                            <th style={{width: "110px", textAlign: "center"}}>VM</th>
                            <th style={{width: "110px", textAlign: "center"}}>NM</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Ma</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2MondayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2MondayAMStart}-{timeTable?.week2MondayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2MondayPMStart}-{timeTable?.week2MondayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Di</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2TuesdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2TuesdayAMStart}-{timeTable?.week2TuesdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2TuesdayPMStart}-{timeTable?.week2TuesdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Wo</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2WednesdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2WednesdayAMStart}-{timeTable?.week2WednesdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2WednesdayPMStart}-{timeTable?.week2WednesdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Do</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2ThursdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2ThursdayAMStart}-{timeTable?.week2ThursdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2ThursdayPMStart}-{timeTable?.week2ThursdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Vr</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2FridayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2FridayAMStart}-{timeTable?.week2FridayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2FridayPMStart}-{timeTable?.week2FridayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Za</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2SaturdayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2SaturdayAMStart}-{timeTable?.week2SaturdayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2SaturdayPMStart}-{timeTable?.week2SaturdayPMEnd}</b></Label>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center p-1">
                                <Label><b>Zo</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2SundayHours}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2SundayAMStart}-{timeTable?.week2SundayAMEnd}</b></Label>
                            </td>
                            <td className="text-center p-1">
                                <Label><b>{timeTable?.week2SundayPMStart}-{timeTable?.week2SundayPMEnd}</b></Label>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    }

    return (
        <div className="p-0 m-0 mt-2">
            <Row>
                <Col className="mb-3 text-center">
                    <ButtonGroup className="p-0" style={{width: "100%"}}>
                        <Button className="p-2" style={{backgroundColor: "#2F5FA1", width: "20%"}} onClick={previousTimeTable} disabled={selectedTimeTableIndex <= 0}>
                            <i className="fas fa-arrow-left fa-md"></i>
                        </Button>     
                        <select className="form-control form-control-md p-0 pl-2 text-center" style={{width: "60%", height: "42px", borderRadius: "0"}} onChange={setTimeTableFromDropDown}>
                            <option key={0} selected={selectedTimeTableIndex === 0} value={0}>Week 1</option>
                            <option key={1} selected={selectedTimeTableIndex === 1} value={1}>Week 2</option>
                        </select>                 
                        <Button className="p-2" style={{backgroundColor: "#2F5FA1", width: "20%"}} onClick={nextTimeTable} disabled={selectedTimeTableIndex >= 1}>
                            <i className="fas fa-arrow-right fa-md"></i>
                        </Button>   
                    </ButtonGroup>
                </Col>
            </Row>
            {selectedTimeTableIndex === 0 ? getTimeTableWeek1() : getTimeTableWeek2()}
        </div>
    );
}
import { Views } from "react-big-calendar";
import { Col, Row } from "reactstrap";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import TodayIcon from '@mui/icons-material/Today';
import WeekIcon from '@mui/icons-material/DateRange';
import MonthIcon from '@mui/icons-material/CalendarMonth';

interface Props {
    setSelectedCalenderUnitAndDate: Function;
    selectedCalenderUnit: string;
    dateLabel: string;
}

const CalenderHeader = (props: Props) => {
    return (
        <Row className="ps-3 pe-3 mt-2 mb-2">
            <Col xs={9} style={{ textAlign: "start", alignSelf: "center", fontWeight: "bold" }}>
                <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle1">{props.dateLabel}</Typography>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={nlLocale}>
                    <DatePicker
                            renderInput={(props) => <TextField variant="standard" inputProps={{disableUnderline: true}} {...props} required/>}
                            value={calenderDate}
                            inputFormat="DD MMMM YYYY"
                            InputProps={{disableUnderline: true}}
                            onChange={(newValue) => {
                                setCalenderDate(dayjs(newValue).toDate());
                        }}
                    />
                </LocalizationProvider> */}
            </Col>
            <Col xs={3} style={{ textAlign: "end" }}>
                <FormControl>
                    <Select
                        variant="standard"
                        value={props.selectedCalenderUnit}
                        onChange={(event: any) => props.setSelectedCalenderUnitAndDate(event.target.value)}
                        disableUnderline>
                        <MenuItem value={Views.MONTH}>
                            <MonthIcon fontSize="medium" color="action" className="me-1" />
                        </MenuItem>
                        <MenuItem value={Views.WEEK}>
                            <WeekIcon color="action" className="me-1" />
                        </MenuItem>
                        <MenuItem value={Views.DAY}>
                            <TodayIcon color="action" className="me-1" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </Col>
        </Row>
    )
}

export default CalenderHeader;
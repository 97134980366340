/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Col, Label, Row } from "reactstrap";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { HeaderType } from '../../Models/Enums/HeaderType';
import { useHistory } from 'react-router-dom';
import EmployeeService from "../../Services/EmployeeService";
import { useForm } from "react-hook-form";
import { LoadSpinner } from "../../Components/LoadSpinner";
import { parseTextToDateFormat } from "../../Services/GlobalService";
import { EmployeeFamily } from "../../Models/EmployeeFamily";
import { CivilStatus, translateCivilStatusEnumValue } from '../../Models/Enums/CivilStatus';
import { SpouseProfession, translateSpouseProfessionEnumValue } from '../../Models/Enums/SpouseProfession';
import { Income, translateIncomeEnumValue } from '../../Models/Enums/Income';
import { toast } from "react-toastify";
import { Header } from "../../Components/Header";

export default function EmployeeFamilyPage() {
    const { instance } = useMsal();
    const history = useHistory();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const [employee, setEmployee] = useState<EmployeeFamily>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { register, reset, setValue, getValues } = useForm();

    useEffect(() => {
        getEmployeeFamily();
    }, []);

    function getEmployeeFamily() {
        setIsLoading(true);

        employeeService.getEmployeeFamily().then((employee) => {
            setEmployee(employee);
            reset(employee);
        }).catch((error) => {
            toast.error("Kan geen werknemer vinden.");
            history.push("/");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function goBackToOverviewGeneralPage(): void {
        history.push("/OverviewEmployee");
    }

    function updateDataOfForm(data: any, item: any) {
        setValue(item, data.target.value);
        // update form values so that fields can be hided when needed
        reset(getValues())
    }

    function updateDateFieldOfForm(data: any, item: string) {
        const empl = getValues() as any;
        empl[item as string] = data.target.value;
        setEmployee(empl);

        setValue(item, data.target.value);
        reset(getValues())
    }

    function getStatusDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Status</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Burgelijke stand:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("civilStatus")} onChange={(data) => updateDataOfForm(data, "civilStatus")} className="form-control" disabled>
                            <option></option>
                            {Object.keys(CivilStatus).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {translateCivilStatusEnumValue(CivilStatus[CivilStatus[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    function getPartnerDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Partner</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Partner naam:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("spouseName")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Partner voornaam:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("spousespouseFirstnameName")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Partner geboortedatum:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="date" value={parseTextToDateFormat(employee?.spouseBirthdate as any)} 
                            onChange={(data) => updateDateFieldOfForm(data, "spouseBirthdate")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Partner geboorteplaats:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("spouseBirthplace")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Huwelijksdatum:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("weddingdate")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Beroep partner:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("spouseProfession")} className="form-control" disabled>
                            {Object.keys(SpouseProfession).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {translateSpouseProfessionEnumValue(SpouseProfession[SpouseProfession[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    function getWithholdingTaxDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Bedrijfsvoorheffing</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Alleenst. met kinderen</Label>
                    </Col>
                    <Col xs="12">
                        <input type="checkbox" {...register("singleWithChildren")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
                {getValues("civilStatus") != CivilStatus.Single &&
                    <>
                        <Row className="ps-2 pe-2 mb-2">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Partner ten laste:</Label>
                            </Col>
                            <Col xs="12">
                                <select {...register("spouseWithIncome")} className="form-control" disabled>
                                    {Object.keys(Income).filter(f => !isNaN(Number(f)))?.map(key => (
                                        <option key={key} value={key}>
                                            {translateIncomeEnumValue(Income[Income[key as any] as any] as any)}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                        <Row className="ps-2 pe-2 mb-2">
                            <Col xs="12" className="mb-1">
                                <Label className="m-0">Partner mindervalide:</Label>
                            </Col>
                            <Col xs="12">
                                <input type="checkbox" {...register("spouseDisabled")} className="checkbox-size form-check-input" disabled/>
                            </Col>
                        </Row>
                    </>
                }
                
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Kinderen ten laste:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("childrenAtCharge")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Waarvan aantal mindervalide ten laste:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("childrenHandicapped")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Andere ten laste:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("othersAtCharge")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Anderen mindervalide ten laste:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("othersHandicapped")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">65+ ten laste:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="number" {...register("others65AtCharge")} className="form-control" readOnly/>
                    </Col>
                </Row>
            </div>
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button variant="outlined" style={{ width: "100%" }} className="p-2 buttons-footer background-color-blue" onClick={goBackToOverviewGeneralPage}>
                        <Row>
                            <Col>
                                <i className="fa-solid fa-arrow-left-long fa-2x"></i>
                            </Col>
                        </Row>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <>
            <Header type={HeaderType.Other} description="Gezin"/>
            <LoadSpinner top={"150px"} isLoading={isLoading} />

            <div className="ps-3 pe-3" style={{height: "calc(100vh - 165px)", overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {employee ?
                    <div className="p-0 m-0 mt-3 text-start">
                        {getStatusDiv()}                        
                        {(getValues("civilStatus") == CivilStatus.Married || getValues("civilStatus") == CivilStatus.LiveTogether || getValues("civilStatus") == CivilStatus.Cohabitation)
                            && getPartnerDiv()}
                        {getWithholdingTaxDiv()}
                    </div>
                : ""}
            </div>

            {getButtonDiv()}
        </>
    );
}
import BaseApiService from "./BaseApiService";
import { LeaveRequest } from '../Models/LeaveRequest';
import { AbsenceRegistration } from '../Models/AbsenceRegistration';
import { BalanceCounter } from "../Models/BalanceCounter";
import { WeeksSchedule } from '../Models/WeeksSchedule';
import { LeaveRequestReportsByYears } from "../Models/LeaveRequestReportsByYears";
import { LeaveRequestReportResponse } from "../Models/Responses/LeaveRequestReportResponse";
import LeaveCode from "../Models/LeaveCode";

export default class AbsenceRegistrationService extends BaseApiService {

    public async getAbsenceRegistration(date: Date): Promise<AbsenceRegistration> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetAbsenceRegistration`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(date)
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        let absenceRegistration = await result.json() as AbsenceRegistration;

        absenceRegistration.leaveRequests = this.convertStringToDate(absenceRegistration.leaveRequests);

        //set allday events for calendar
        absenceRegistration.leaveRequests.forEach(obj => obj.allDay = (obj.showAsWholeDayEvent === true))

        return absenceRegistration;
    }

    public async getLeaveRequestsByDate(date: Date): Promise<LeaveRequest[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetLeaveRequestsByDate`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(date)
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const leaveRequests = await result.json() as LeaveRequest[];

        //set allday events for calendar
        leaveRequests.forEach(obj => obj.allDay = (obj.showAsWholeDayEvent === true))

        return this.convertStringToDate(leaveRequests);
    }

    public async getLeaveCodes(): Promise<LeaveCode[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetLeaveCodes`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return await result.json() as LeaveCode[];
    }

    private convertStringToDate(leaveRequests: LeaveRequest[]): LeaveRequest[] {
        for (let i in leaveRequests) {
            leaveRequests[i].start = new Date(leaveRequests[i].start);
            leaveRequests[i].end = new Date(leaveRequests[i].end);
        }

        return leaveRequests;
    }

    public async getBalanceCountersByYear(date: Date): Promise<BalanceCounter[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetBalanceCountersByYear`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(date)
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return await result.json() as BalanceCounter[];
    }

    public async getWeeksSchedulesByYear(date: Date): Promise<WeeksSchedule[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetWeeksSchedulesByYear`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(date)
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return await result.json() as WeeksSchedule[];
    }

    public async createOrUpdateLeaveRequest(leaveRequest: LeaveRequest): Promise<boolean> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/CreateOrUpdateLeaveRequest`;
        let isSuccess = false;

        const token = await this.acquireAccessToken();

        await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(leaveRequest)
        }).then(async function (response) {
            if (!response.ok) {
                const error = await response.text();
                throw new Error(error);
            }

            await response.json().then(data => {
                if (data) {
                    isSuccess = true;
                }
            });
        }).catch((error: Error) => {
            throw new Error(error.message);
        });

        return isSuccess;
    }

    public async deleteLeaveRequest(leaveRequestId: number): Promise<boolean> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/DeleteLeaveRequest/${leaveRequestId}`;
        let isSuccess = false;
        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'delete',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        } else {
            isSuccess = true;
        }

        return isSuccess;
    }

    public async getLeaveRequestsByStatus(year: number, type: string): Promise<LeaveRequestReportsByYears> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetLeaveRequestReports`;

        const response: LeaveRequestReportResponse = {
            year: year,
            type: type
        }

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(response)
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return await result.json() as LeaveRequestReportsByYears;
    }

}
import { Col, Row } from "reactstrap";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface IconProps {
    goToFunction: Function,
    label: string,
    icon: any
}

interface Props {
    goBackFunction: Function;
    iconsProps: IconProps[];
};

const HeaderWithIcons = (props: Props) => {
    return (
        <header className="simple-header" style={{ width: "100%" }}>
            <Row>
                <Col xs={6} style={{ textAlign: "start" }}>
                    <IconButton 
                        className="ms-1" 
                        color="inherit" 
                        onClick={() => props.goBackFunction()} 
                        aria-label="Terug"
                    >
                        <ArrowBackIcon color="inherit" fontSize="large" />
                    </IconButton>
                </Col>
                <Col xs={6} style={{ textAlign: "end" }}>
                    {props.iconsProps.map((iconButton: IconProps, index: number) => (
                        <IconButton key={index} 
                            className="ms-1" 
                            color="inherit" 
                            onClick={() => iconButton.goToFunction()} 
                            aria-label={iconButton.label}
                        >
                            {iconButton.icon}
                        </IconButton>
                    ))}
                </Col>
            </Row>
        </header>
    );
}

export default HeaderWithIcons;

/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment'
import { Calendar, Views, momentLocalizer, stringOrDate } from 'react-big-calendar'
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Alert } from "@mui/material";
import "./AbsenceRegistrationCalender.css";
import { LeaveRequestStatus } from "../../../Models/Enums/LeaveRequestStatus";
import AbsenceRegistrationService from "../../../Services/AbsenceRegistrationService";
import { useMsal } from "@azure/msal-react";
import { LeaveRequest } from '../../../Models/LeaveRequest';
import { toast } from "react-toastify";
import { AbsenceRegistration } from '../../../Models/AbsenceRegistration';
import { CalenderEvent } from "../../../Models/CalenderEvent";
import { WeeksSchedule } from "../../../Models/WeeksSchedule";
import LeaveRequestsNotificationPopup from "../../../Components/AbsenceRegistrationTool/Popups/LeaveRequestsNotificationPopup";
import { LoadSpinner } from "../../../Components/LoadSpinner";
import useWindowHeight from "../../../hooks/useWindowHeight";
import LeaveRequestCard from "../../../Components/AbsenceRegistrationTool/AbsenceRegistrationCalender/LeaveRequestCard";
import dayjs from "dayjs";
import CalenderFooterNavigation from "../../../Components/AbsenceRegistrationTool/AbsenceRegistrationCalender/CalenderFooterNavigation";
import CalenderHeader from "../../../Components/AbsenceRegistrationTool/AbsenceRegistrationCalender/CalenderHeader";
import HeaderWithIcons from "../../../Components/HeaderWithIcons";
import RefreshIcon from '@mui/icons-material/Refresh';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import CustomToolbar from "../../../Components/AbsenceRegistrationTool/AbsenceRegistrationCalender/CustomToolbar";
import { setPushNotificationsUserIdIfEnabled } from "../../../Helpers/OneSignalNotificationsHelper";

require('moment/locale/nl.js')

// the required distance between touchStart and touchEnd to be detected as a swipe
const minSwipeDistance = 50;

export default function AbsenceRegistrationCalender() {
    const history = useHistory();
    const location = useLocation();
    const states = location.state as any;
    const goToDate = states?.goToDate as Date | null;
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const absenceRegistrationService = useMemo(() => new AbsenceRegistrationService(instance), [instance]);
    const [calenderDate, setCalenderDate] = useState<Date>(goToDate ?? new Date());
    const [selectedCalenderUnit, setSelectedCalenderUnit] = useState<string>(Views.MONTH);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [leaveRequests, setLeaveRequests] = useState<CalenderEvent[]>([]);
    const [leaveRequestsNotification, setLeaveRequestsNotification] = useState<LeaveRequest[]>([]);
    const [, setWeeksSchedules] = useState<WeeksSchedule[]>([]);
    const [selectedLeaveRequest, setSelectedLeaveRequest] = useState<LeaveRequest>();
    const [lastDateSendToPrisma, setLastDateSendToPrisma] = useState<Date | undefined>();
    const [isMonthClosed, setIsMonthClosed] = useState<boolean>(false);
    const [isLeaveRequestsNotificationPopupOpen, setIsLeaveRequestsNotificationPopupOpen] = useState<boolean>(false);
    const [dateLabel, setDateLabel] = useState<string>("");
    const [canLeaveRequestBeCreated, setCanLeaveRequestBeCreated] = useState<boolean>(true);
    const { windowHeight } = useWindowHeight();

    useEffect(() => {
        setPushNotificationsUserIdIfEnabled();
    }, []);

    // Swipe gestures
    const onTouchStart = (e: any) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e: any) => {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd)
            return;

        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe || isRightSwipe) {
            if (isLeftSwipe) {
                goToNextDate();
            } else {
                goToPreviousDate();
            }
        }
    }

    const refreshLeaveRequests = useCallback(() => {
        setIsLoading(true);

        absenceRegistrationService.getAbsenceRegistration(calenderDate).then((absenceRegistration: AbsenceRegistration) => {
            const calenderEvents = absenceRegistration?.leaveRequests.map(obj => ({ ...obj, title: obj.leaveCodeName }));

            setLeaveRequests(calenderEvents);
            setWeeksSchedules(absenceRegistration.weeksSchedules);

            const lastDateSendToPrisma = moment(absenceRegistration.lastDateSendToPrisma).toDate();
            const currentDateWihoutTime = new Date(calenderDate.setHours(0, 0, 0, 0));
            setLastDateSendToPrisma(lastDateSendToPrisma);
            setIsMonthClosed(lastDateSendToPrisma ? currentDateWihoutTime <= lastDateSendToPrisma : false);

            showLeaveRequestNotifications(absenceRegistration?.leaveRequestsNotification);
        }).catch(() => {
            toast.error("Er is iets fout gegaan bij het ophalen van de verlofaanvragen.");
            history.push("/");
        }).finally(() => {
            setIsLoading(false);
        });
    }, [absenceRegistrationService, calenderDate, history]);

    useEffect(() => {
        refreshLeaveRequests();
    }, [refreshLeaveRequests]);

    function showLeaveRequestNotifications(leaveRequestsNotification: LeaveRequest[]) {
        setLeaveRequestsNotification(leaveRequestsNotification);
        if (!leaveRequestsNotification)
            return;

        if (leaveRequestsNotification?.length > 0)
            setIsLeaveRequestsNotificationPopupOpen(true);
    }

    function goBackToHomePage(): void {
        // console.log("goBackToHomePage");
        history.push("/");
    }

    function handleNavigate(focusDate: Date) {
        // console.log("handleNavigate");
        checkForRefreshLeaveRequestsAndSetDate(focusDate, selectedCalenderUnit);
    }

    function checkForRefreshLeaveRequestsAndSetDate(date: Date, calenderUnit: string) {
        if (calenderDate.getMonth() != date.getMonth()) {
            setIsLoading(true);

            absenceRegistrationService.getLeaveRequestsByDate(date).then((leaveRequests: LeaveRequest[]) => {
                const calenderEvents = leaveRequests.map(obj => ({ ...obj, title: obj.leaveCodeName }));

                setLeaveRequests(calenderEvents);
            }).catch(() => {
                toast.error("Er is iets fout gegaan bij het ophalen van de verlofaanvragen.");
            }).finally(() => {
                setCalenderDate(date);
                setIsLoading(false);
            });
        } else {
            setCalenderDate(date);
        }

        checkIfCalenderDateIsBeforeCurrentDate(date, calenderUnit);
    }

    function checkIfCalenderDateIsBeforeCurrentDate(date: Date, calenderUnit: string) {
        const currentDate = new Date();
        let canBeCreated = true;

        if (date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear()) {
            if (calenderUnit === Views.WEEK) {
                canBeCreated = dayjs(date).isSame(currentDate, 'week') || date.getDate() > currentDate.getDate();
            } else if (calenderUnit === Views.DAY && date.getDate() < currentDate.getDate()) {
                canBeCreated = false;
            }
        } else if (date < currentDate) {
            canBeCreated = false;
        }

        setCanLeaveRequestBeCreated(canBeCreated);
        setIsMonthClosed(lastDateSendToPrisma ? date <= lastDateSendToPrisma : false);
    }

    function goToNextDate() {
        if (isLoading)
            return;

        let newDate = new Date(calenderDate);

        if (selectedCalenderUnit == Views.MONTH) {
            newDate = new Date(newDate.setDate(2));
            newDate = new Date(newDate.setMonth(newDate.getMonth() + 1));
        } else if (selectedCalenderUnit == Views.WEEK) {
            newDate = new Date(newDate.setDate(newDate.getDate() + 7));
        } else if (selectedCalenderUnit == Views.DAY) {
            newDate = new Date(newDate.setDate(newDate.getDate() + 1));
        }

        checkForRefreshLeaveRequestsAndSetDate(newDate, selectedCalenderUnit);
    }

    function goToPreviousDate() {
        if (isLoading)
            return;

        let newDate = new Date(calenderDate);

        if (selectedCalenderUnit == Views.MONTH) {
            newDate = new Date(newDate.setDate(2));
            newDate = new Date(newDate.setMonth(newDate.getMonth() - 1));
        } else if (selectedCalenderUnit == Views.WEEK) {
            newDate = new Date(newDate.setDate(newDate.getDate() - 7));
        } else if (selectedCalenderUnit == Views.DAY) {
            newDate = new Date(newDate.setDate(newDate.getDate() - 1));
        }

        checkForRefreshLeaveRequestsAndSetDate(newDate, selectedCalenderUnit);
    }

    function goToCurrentDate() {
        if (isLoading)
            return;

        setSelectedCalenderUnit(Views.MONTH);
        checkForRefreshLeaveRequestsAndSetDate(new Date(), Views.MONTH);
    }

    function goToMonthView() {
        if (isLoading)
            return;

        setSelectedCalenderUnit(Views.MONTH)
        checkForRefreshLeaveRequestsAndSetDate(calenderDate, Views.MONTH);
    }

    function getEventColor(event: LeaveRequest): any {
        const backgroundStyle = event.status === LeaveRequestStatus.Pending &&
            "repeating-linear-gradient(-45deg, transparent, transparent 3px, #9990 7px, white 10px)";

        return {
            style: {
                backgroundImage: backgroundStyle,
                backgroundColor: event.colorCode,
                borderColor: event.colorCode,
                borderRadius: 2
            }
        }
    }

    function handleSelectSlot(slotInfo: {
        start: stringOrDate;
        end: stringOrDate;
        slots: Date[] | string[];
        action: 'select' | 'click' | "doubleClick";
    }) {
        // console.log("handleSelectSlot");
        if (slotInfo.action === "click" && selectedCalenderUnit == Views.MONTH) {

            history.push({
                pathname: '/NewLeaveRequest',
                state: {
                    selectedDate: new Date(slotInfo.start),
                }
            });
        }
    };

    function handleSelectEvent(event: LeaveRequest): void {
        // console.log("handleSelectEvent");
        setSelectedLeaveRequest(event);
    }

    function handleOnView(view: any) {
        // console.log("handleOnView");
        setSelectedCalenderUnit(view)
    }

    const { defaultDate, scrollToTime } = useMemo(() => ({
        defaultDate: new Date(),
        scrollToTime: new Date(1970, 1, 1, 6),
    }), []);

    function setSelectedCalenderUnitAndDate(calenderUnit: string) {
        // console.log("setSelectedCalenderUnitAndDate");
        let date = calenderDate;

        if (calenderUnit === Views.WEEK || calenderUnit === Views.DAY) {
            date = new Date();
            setCalenderDate(date);
        }

        setSelectedCalenderUnit(calenderUnit);
        checkForRefreshLeaveRequestsAndSetDate(date, calenderUnit);
    }

    function leaveRequestDiv() {
        const monthClosedAlertPixels = isMonthClosed ? 52 : 0;

        return (
            <div style={{ width: "100%" }}>
                <CalenderHeader
                    setSelectedCalenderUnitAndDate={setSelectedCalenderUnitAndDate}
                    selectedCalenderUnit={selectedCalenderUnit}
                    dateLabel={dateLabel}
                />

                {isMonthClosed &&
                    <div className="ps-2 pe-2 pb-1">
                        <Alert severity="error">Deze maand is reeds afgesloten.</Alert>
                    </div>
                }

                <Calendar
                    style={{
                        height: !selectedLeaveRequest ?
                            `calc(${windowHeight}px - 190px - ${monthClosedAlertPixels}px)` :
                            `calc(${windowHeight}px - 340px - ${monthClosedAlertPixels}px)`
                    }}
                    eventPropGetter={getEventColor}
                    defaultDate={defaultDate}
                    events={leaveRequests}
                    localizer={momentLocalizer(moment)}
                    date={calenderDate}
                    culture='eu-be'
                    onNavigate={handleNavigate}
                    onSelectEvent={handleSelectEvent}
                    showMultiDayTimes={true}
                    view={selectedCalenderUnit as any}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onView={handleOnView}
                    views={[Views.MONTH, Views.WEEK, Views.DAY]}
                    components={{
                        toolbar: event =>
                            <CustomToolbar
                                toolbar={event}
                                setDateLabel={setDateLabel}
                                dateLabel={dateLabel}
                            />,
                    }}
                    scrollToTime={scrollToTime}
                />

                <CalenderFooterNavigation
                    goToPreviousDate={goToPreviousDate}
                    goToCurrentDate={goToCurrentDate}
                    goToMonthView={goToMonthView}
                    goToNextDate={goToNextDate}
                    selectedCalenderUnit={selectedCalenderUnit}
                />
                {getSelectedLeaveRequestDiv()}
            </div>
        );
    }

    function closeSelectedLeaveRequest() {
        setSelectedLeaveRequest(null as any);
    }

    function getSelectedLeaveRequestDiv() {
        if (!selectedLeaveRequest)
            return;

        return (
            <div className="m-2 mt-4 text-start">
                <LeaveRequestCard
                    leaveRequest={selectedLeaveRequest}
                    closeSelectedLeaveRequest={() => closeSelectedLeaveRequest()}
                    goToDetailsLeaveRequestPage={() => goToDetailsLeaveRequestPage(selectedLeaveRequest)}
                />
            </div>
        );
    }

    function goToBalanceCounters(): void {
        history.push({
            pathname: '/BalanceCounters',
            state: {
                calenderDate: calenderDate
            }
        });
    }

    function goToNewLeaveRequestPage(): void {
        // console.log("goToNewLeaveRequestPage");
        let date = calenderDate;

        if (selectedCalenderUnit === Views.MONTH || selectedCalenderUnit === Views.WEEK) {
            date = new Date();
        }

        history.push({
            pathname: '/NewLeaveRequest',
            state: {
                selectedDate: date,
            }
        });
    }

    function goToDetailsLeaveRequestPage(leaveRequest: LeaveRequest): void {
        const startDate = new Date(leaveRequest.start.setHours(leaveRequest.start.getHours(), leaveRequest.start.getMinutes(), 0, 0));
        const isMonthClosed = lastDateSendToPrisma ? startDate <= lastDateSendToPrisma : false;

        history.push({
            pathname: '/DetailsLeaveRequest',
            state: {
                leaveRequest: leaveRequest,
                isMonthClosed: isMonthClosed
            }
        });
    }

    function getLeaveRequestsNotificationPopup() {
        if (!leaveRequestsNotification || leaveRequestsNotification.length === 0)
            return;

        return (
            <LeaveRequestsNotificationPopup
                isPopupOpen={isLeaveRequestsNotificationPopupOpen}
                setIsPopupOpen={setIsLeaveRequestsNotificationPopupOpen}
                leaveRequests={leaveRequestsNotification} />
        );
    }

    return (
        <>
            <HeaderWithIcons
                goBackFunction={goBackToHomePage}
                iconsProps={[
                    {
                        goToFunction: () => !isLoading && goToBalanceCounters(),
                        label: "Saldotellers",
                        icon: <InsertChartOutlinedIcon color="inherit" fontSize="large" />
                    },
                    {
                        goToFunction: () => !isLoading && refreshLeaveRequests(),
                        label: "Vernieuw",
                        icon: <RefreshIcon color="inherit" fontSize="large" />
                    }
                ]}
            />

            <LoadSpinner top={"65px"} isLoading={isLoading} />

            {getLeaveRequestsNotificationPopup()}

            <div id="calenderDiv" style={{ height: `calc(${windowHeight}px - 59px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}
                onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                {leaveRequests &&
                    <div className="p-0 m-0 mb-3">
                        {leaveRequestDiv()}
                    </div>
                }
            </div>

            {!isMonthClosed && canLeaveRequestBeCreated &&
                <IconButton className="position-absolute bottom-0 end-0 color-blue p-0" style={{ opacity: 0.8 }}
                    onClick={() => !isLoading && goToNewLeaveRequestPage()} aria-label="Nieuwe verlofaanvraag">
                    <AddCircleIcon color="inherit" style={{ fontSize: 60 }} />
                </IconButton>
            }
        </>
    );
}
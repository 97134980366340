import { Box, Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';

interface Props {
    icon: JSX.Element;
    description: string;
    color: string;
}

export function Tile(props: Props) {
    return (
        <Card className="mb-3">
            <CardActionArea>
                <CardHeader className="pb-0" title={
                    <div style={{color: props.color, fontSize: 60}}>
                        {props.icon}
                    </div>
                }>
                </CardHeader>
                <CardContent style={{padding: 0}}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "center", height: 60}}>
                        <Typography>{props.description}</Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
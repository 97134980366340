import { EmployeeHours } from './../Models/EmployeeHours';
import { EmployeeFamily } from './../Models/EmployeeFamily';
import BaseApiService from "./BaseApiService";
import { EmployeeGeneral } from '../Models/EmployeeGeneral';
import { EmployeeContract } from '../Models/EmployeeContract';
import { EmployeeWage } from '../Models/EmployeeWage';

export default class EmployeeService extends BaseApiService {

    public async getEmployeeGeneral(): Promise<EmployeeGeneral> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetEmployeeGeneral`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            })
        });

        if (!result.ok) {
            throw Error(await result.text());
        }

        const employee = await result.json() as EmployeeGeneral;
        return employee;
    }

    public async getEmployeeFamily(): Promise<EmployeeFamily> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetEmployeeFamily`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const employee = await result.json() as EmployeeFamily;
        return employee;
    }

    public async getEmployeeContract(): Promise<EmployeeContract> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetEmployeeContract`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const employee = await result.json();
        return employee;
    }

    public async getEmployeeHours(): Promise<EmployeeHours> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetEmployeeHours`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const employee = await result.json();
        return employee;
    }

    public async getEmployeeWage(): Promise<EmployeeWage> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetEmployeeWage`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const employee = await result.json();
        return employee;
    }
}

import { User } from "../Models/User";
import BaseApiService from "../Services/BaseApiService";

export default class ApiService extends BaseApiService {

    public async getInvitationToken(): Promise<string> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetInvitationToken`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const invitationToken = await result.text();

        return invitationToken;
    }

    public async getHeaders(): Promise<string[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/GetHeaders`;

        const token = await this.acquireAccessToken();

        const result = await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        const headers = await result.json();
        return headers;
    }

    public async createUser(personId: number, principalId: string, reinvite: boolean, email: string): Promise<boolean> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/api/CreateUser`;
        let doesUserExist: boolean = true;

        const token = await this.acquireAccessToken();

        const user: User = {
            id: null,
            personId: personId,
            principalId: principalId,
            reinvite: reinvite,
            email: email
        }

        await fetch(url, {
            method: 'post',
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(user)
        }).then(async function (response) {
            if (!response.ok) {
                const error = await response.text();
                throw new Error(error);
            }

            await response.json().then(data => {
                doesUserExist = data;
            });
        }).catch((error: Error) => {
            throw new Error(error.message);
        });

        return doesUserExist;
    }
}
import { Card, CardContent, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
    description: string;
    onClickFunction: Function;
    isDisabled: boolean;
    icon: any;
}

const SettingsItem = (props: Props) => {

    return (
        <Card 
            sx={{ border: "1px solid var(--light-gray-card-border)", boxShadow: 1 }} 
            className="mb-3"
            onClick={() => props.onClickFunction()}
        >
            <CardContent className="p-0">
                <ListItemButton 
                    disabled={props.isDisabled} 
                    className="background-color-light-gray"
                >
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" className="color-gray">
                                <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            {props.icon}
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.description}
                        />
                    </ListItem>
                </ListItemButton>
            </CardContent>
        </Card>
    )
}

export default SettingsItem;
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, Tabs } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import Tab from '@mui/material/Tab';
import SwipeableViews from "react-swipeable-views";
import { BalanceCounter } from "../../Models/BalanceCounter";
import AbsenceRegistrationService from "../../Services/AbsenceRegistrationService";
import { DonutChartCard } from "../../Components/AbsenceRegistrationTool/DonutChartCard";
import { LoadSpinner } from "../../Components/LoadSpinner";
import useWindowHeight from "../../hooks/useWindowHeight";
import ScheduleIcon from '@mui/icons-material/Schedule';
import HeaderWithIcons from "../../Components/HeaderWithIcons";

enum YearTabs {
    PreviousYear = 0,
    CurrentYear,
    NextYear
};

export default function BalanceCounters() {
    const currentYear = new Date().getFullYear();
    const history = useHistory();
    const location = useLocation();
    const states = location.state as any;
    const calenderDate = states.calenderDate as Date;
    const goToDate = states.goToDate as Date;
    const { instance } = useMsal();
    const [isBalanceCountersLoading, setIsBalanceCountersLoading] = useState<boolean>(true);
    const [balanceCounters, setBalanceCounters] = useState<BalanceCounter[]>();
    const [balanceCountersPrevious, setBalanceCountersPrevious] = useState<BalanceCounter[]>();
    const [balanceCountersNext, setBalanceCountersNext] = useState<BalanceCounter[]>();
    const { windowHeight } = useWindowHeight();
    const absenceRegistrationService = useMemo(() => new AbsenceRegistrationService(instance), [instance]);
    const [selectedTab, setSelectedTab] = useState<YearTabs>(getSelectedTabOnInit());

    const getBalanceCountersByYear = useCallback((year: number) => {
        setIsBalanceCountersLoading(true);

        Promise.all([
            absenceRegistrationService.getBalanceCountersByYear(new Date(year - 1, 1, 1)),
            absenceRegistrationService.getBalanceCountersByYear(new Date(year, 1, 1)),
            absenceRegistrationService.getBalanceCountersByYear(new Date(year + 1, 1, 1))
        ]).then((values: any) => {
            setBalanceCountersPrevious(values[0] as BalanceCounter[]);
            setBalanceCounters(values[1] as BalanceCounter[]);
            setBalanceCountersNext(values[2] as BalanceCounter[]);
        }).finally(() => {
            setIsBalanceCountersLoading(false);
        });
    }, [absenceRegistrationService]);
    
    useEffect(() => {
        getBalanceCountersByYear(currentYear)
    }, [currentYear, getBalanceCountersByYear]);

    function getSelectedTabOnInit(): YearTabs {
        const date = goToDate ?? calenderDate;

        if (date.getFullYear() <= currentYear - 1)
            return YearTabs.PreviousYear;

        if (date.getFullYear() >= currentYear + 1)
            return YearTabs.NextYear;

        return YearTabs.CurrentYear;
    }

    function goBackToCalendar(): void {
        history.push({
            pathname: '/AbsenceRegistrationCalender',
            state: {
                goToDate: calenderDate
            }
        })
    }

    function goToLeaveRequestReports(balanceCounter: BalanceCounter | null): void {
        let year = new Date().getFullYear();

        if (selectedTab === YearTabs.PreviousYear) {
            year--;
        } else if (selectedTab === YearTabs.NextYear) {
            year++;
        }

        const date = new Date(year, 1, 1);

        history.push({
            pathname: '/LeaveRequestReports',
            state: {
                goToDate: date,
                calenderDate: calenderDate,
                type: balanceCounter?.type ?? "All",
                typeDescription: (balanceCounter?.description ?? balanceCounter?.typeName) ?? "Verlofaanvragen"
            }
        });
    }

    function getBalanceCounterPreviousCharts() {
        if (!balanceCountersPrevious || isBalanceCountersLoading)
            return <></>;

        return (
            <Row className="m-0 p-0 row flex-row" style={{ width: "100%", overflowX: "hidden" }}>
                {balanceCountersPrevious?.length > 0 ?
                    balanceCountersPrevious.map((balanceCounter: BalanceCounter) => {
                        return (
                            <Col xs={6} md={4} key={balanceCounter.id}>
                                <DonutChartCard 
                                    text={balanceCounter.description ?? balanceCounter.typeName} 
                                    hoursLeft={balanceCounter.hoursLeft}
                                    totalHours={balanceCounter.totalHours} 
                                    color={balanceCounter.colorCode}
                                    onClickFunction={() => goToLeaveRequestReports(balanceCounter)}
                                />
                            </Col>
                        );
                    })
                    :
                    <Alert severity="error">Geen saldotellers gevonden.</Alert>
                }
            </Row>
        );
    }

    function getBalanceCounterCharts() {
        if (!balanceCounters || isBalanceCountersLoading)
            return <></>;

        return (
            <Row className="m-0 p-0 row flex-row" style={{ width: "100%", overflowX: "hidden" }}>
                {balanceCounters?.length > 0 ?
                    balanceCounters.map((balanceCounter: BalanceCounter) => {
                        return (
                            <Col xs={6} md={4} key={balanceCounter.id}>
                                <DonutChartCard
                                    text={balanceCounter.description ?? balanceCounter.typeName}
                                    hoursLeft={balanceCounter.hoursLeft}
                                    totalHours={balanceCounter.totalHours}
                                    color={balanceCounter.colorCode}
                                    onClickFunction={() => goToLeaveRequestReports(balanceCounter)}
                                />
                            </Col>
                        );
                    })
                    :
                    <Alert severity="error">Geen saldotellers gevonden.</Alert>
                }
            </Row>
        );
    }

    function getBalanceCounterNextCharts() {
        if (!balanceCountersNext || isBalanceCountersLoading)
            return <></>;

        return (
            <Row className="m-0 p-0 row flex-row" style={{ width: "100%", overflowX: "hidden" }}>
                {balanceCountersNext?.length > 0 ?
                    balanceCountersNext.map((balanceCounter: BalanceCounter) => {
                        return (
                            <Col xs={6} md={4} key={balanceCounter.id}>
                                <DonutChartCard 
                                    text={balanceCounter.description ?? balanceCounter.typeName} 
                                    hoursLeft={balanceCounter.hoursLeft}
                                    totalHours={balanceCounter.totalHours} 
                                    color={balanceCounter.colorCode} 
                                    onClickFunction={() => goToLeaveRequestReports(balanceCounter)}
                                />
                            </Col>
                        );
                    })
                    :
                    <Alert severity="error">Geen saldotellers gevonden.</Alert>
                }
            </Row>
        );
    }

    function getBottomYearsTabs() {
        if (selectedTab == null)
            return;

        return (
            <div className="fixed-bottom" style={{ color: "var(--blue-color)" }}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    onChange={(_event, value) => setSelectedTab(value)}>
                    <Tab label={currentYear - 1} value={YearTabs.PreviousYear} />
                    <Tab label={currentYear} value={YearTabs.CurrentYear} />
                    <Tab label={currentYear + 1} value={YearTabs.NextYear} />
                </Tabs>
            </div>
        );
    }

    function getTabsViews() {
        return (
            <SwipeableViews
                axis={'x'}
                index={selectedTab}
                onChangeIndex={(index) => setSelectedTab(index)}>
                <div className="pt-3 ps-3 pe-3" style={{ height: `calc(${windowHeight}px - 109px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                    {getBalanceCounterPreviousCharts()}
                </div>
                <div className="pt-3 ps-3 pe-3" style={{ height: `calc(${windowHeight}px - 109px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                    {getBalanceCounterCharts()}
                </div>
                <div className="pt-3 ps-3 pe-3" style={{ height: `calc(${windowHeight}px - 109px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}>
                    {getBalanceCounterNextCharts()}
                </div>
            </SwipeableViews>
        )
    };

    return (
        <>
            <HeaderWithIcons 
                goBackFunction={goBackToCalendar}
                iconsProps={[
                    {
                        goToFunction: () => !isBalanceCountersLoading && goToLeaveRequestReports(null),
                        label: "Saldotellers",
                        icon: <ScheduleIcon color="inherit" fontSize="large" />
                    }
                ]}
            />

            <div className="m-0" >
                <LoadSpinner top={"65px"} isLoading={isBalanceCountersLoading} />
                {getTabsViews()}
            </div>

            {getBottomYearsTabs()}
        </>
    );
}

export enum CarEngineType {
    Unknown,
    Gasoline,
    Diesel,
    LPG,
    Electric,
    Hybrid,
    Other
}

export function translateCarEngineTypeEnumValue(type: CarEngineType): string {
    switch(type) { 
        case CarEngineType.Unknown: {
           return "Onbekend";
        } 
        case CarEngineType.Gasoline: {
            return "Benzine";
        } 
        case CarEngineType.Diesel: {
            return "Diesel";
        } 
        case CarEngineType.LPG: {
            return "LPG";
        } 
        case CarEngineType.Electric: {
            return "Elektrisch";
        } 
        case CarEngineType.Hybrid: {
            return "Hybride";
        } 
        case CarEngineType.Other: {
            return "Andere brandstof";
        } 
        default: { 
            return "";
        } 
    } 
}
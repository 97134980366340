export enum EmploymentStatus {
    Workman,
    Employee,
    Director
}

export function translateEmploymentStatusEnumValue(type: EmploymentStatus): string {
    switch(type) { 
        case EmploymentStatus.Workman: {
            return "Arbeider";
        } 
        case EmploymentStatus.Employee: {
            return "Bediende";
        } 
        case EmploymentStatus.Director: {
            return "Zelfstandige";
        } 
        default: { 
            return "";
        } 
    } 
}
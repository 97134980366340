/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { HeaderType } from '../../../Models/Enums/HeaderType';
import { useHistory } from 'react-router-dom';
import EmployeeService from "../../../Services/EmployeeService";
import { useForm } from "react-hook-form";
import { LoadSpinner } from "../../../Components/LoadSpinner";
import { EmployeeHours } from "../../../Models/EmployeeHours";
import { translateWorkingTimeEnumValue, WorkingTime } from '../../../Models/Enums/WorkingTime';
import { PartTimeDetail, translatePartTimeDetailEnumValue } from '../../../Models/Enums/PartTimeDetail';
import TimeTable from "../../../Components/Employee/EmployeeHours/TimeTable/TimeTable";
import { toast } from "react-toastify";
import { Header } from "../../../Components/Header";

export default function EmployeeHoursPage() {
    const { instance } = useMsal();
    const history = useHistory();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const [employee, setEmployee] = useState<EmployeeHours>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showTimeTablePopup, setShowTimeTablePopup] = useState<boolean>(false);
    const { register, reset, setValue, getValues } = useForm();

    useEffect(() => {
        getEmployeeContract();
    }, []);

    function getEmployeeContract() {
        setIsLoading(true);

        employeeService.getEmployeeHours().then((employee) => {
            setEmployee(employee);
            reset(employee);
        }).catch((error) => {
            toast.error("Kan geen werknemer vinden.");
            history.push("/");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function goBackToOverviewGeneralPage(): void {
        history.push("/OverviewEmployee");
    }

    function updateDataOfForm(data: any, item: any) {
        setValue(item, data.target.value);
        // update form values so that fields can be hided when needed
        reset(getValues())
    }

    function getTimeTablePopup() {
        return (
            <Modal isOpen={showTimeTablePopup} toggle={() => setShowTimeTablePopup(false)} centered>
                <ModalHeader className="d-flex justify-content-center">Uurroosters</ModalHeader>
                <ModalBody className="p-2">
                    <Row className="ps-2 pe-2 mb-1">
                        <TimeTable timeTable={employee?.timeTable as any}/>
                    </Row>
                    <div className="p-0 text-center">
                        <Button color="primary" className="background-color-blue" style={{ width: "100%" }} onClick={() => setShowTimeTablePopup(false)}>Sluit</Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    function getWorkingHoursDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Arbeidsduur</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Arbeidsregeling:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("workingTime")} onChange={(data) => updateDataOfForm(data, "workingTime")} className="form-control" disabled>
                            <option></option>
                            {Object.keys(WorkingTime).filter(f => !isNaN(Number(f)))?.map(key => (
                                <option key={key} value={key}>
                                    {translateWorkingTimeEnumValue(WorkingTime[WorkingTime[key as any] as any] as any)}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                {getValues("workingTime") == WorkingTime.PartTime &&
                    <Row className="ps-2 pe-2 mb-2">
                        <Col xs="12" className="mb-1">
                            <Label className="m-0">Deeltijds:</Label>
                        </Col>
                        <Col xs="12">
                            <select {...register("partTimeDetail")} className="form-control" disabled>
                                <option></option>
                                {Object.keys(PartTimeDetail).filter(f => !isNaN(Number(f)))?.map(key => (
                                    <option key={key} value={key}>
                                        {translatePartTimeDetailEnumValue(PartTimeDetail[PartTimeDetail[key as any] as any] as any)}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                }
                
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Aantal uur per week:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("weekhoursWorkerAverage")} className="form-control" readOnly/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Compensatierust:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="checkbox" {...register("compensationRest")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
            </div>
        );
    }

    function getTimeTableDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Uurrooster</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs={12}>
                        <Button style={{width: "100%"}} className="background-color-blue" onClick={() => setShowTimeTablePopup(true)}>Open het uurrooster</Button>
                    </Col>
                </Row>
            </div>
        );
    }

    function getDiverseDiv() {
        return (
            <div className="mb-3">
                <h5 className="mb-2"><b>Diversen</b></h5>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Afdeling:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("division")} className="form-control" disabled>
                            {employee?.divisionList.map(division => (
                                <option key={division.code} value={division.code}>
                                    {division.name}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Kostenplaats:</Label>
                    </Col>
                    <Col xs="12">
                        <select {...register("costCentre")} className="form-control" disabled>
                            {employee?.costCenterList.map(costCenter => (
                                <option key={costCenter.code} value={costCenter.code}>
                                    {costCenter.name}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Dimona aangifte uitvoeren:</Label>
                    </Col>
                    <Col xs="12">
                        {/* <div className="form-check">
                            <input className="form-check-input" type="radio" value="true" 
                                onChange={() => updateRadioButtonOfForm("true", "executeDimona")} checked={getValues("executeDimona") as boolean}/>
                            <label className="form-check-label">Ja</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" value="false" 
                                onChange={() => updateRadioButtonOfForm("false", "executeDimona")} checked={!(getValues("executeDimona") as boolean)}/>
                            <label className="form-check-label">Nee</label>
                        </div> */}
                        <input type="checkbox" {...register("executeDimona")} className="checkbox-size form-check-input" disabled/>
                    </Col>
                </Row>
                <Row className="ps-2 pe-2 mb-2">
                    <Col xs="12" className="mb-1">
                        <Label className="m-0">Dimonanummer:</Label>
                    </Col>
                    <Col xs="12">
                        <input type="text" {...register("dimonaNumber")} className="form-control" readOnly/>
                    </Col>
                </Row>
            </div>
        );
    }

    function getButtonDiv() {
        return (
            <>
                <ButtonGroup className="fixed-bottom navbar p-0">
                    <Button variant="outlined" style={{ width: "100%" }} className="p-2 buttons-footer background-color-blue" onClick={goBackToOverviewGeneralPage}>
                        <Row>
                            <Col>
                                <i className="fa-solid fa-arrow-left-long fa-2x"></i>
                            </Col>
                        </Row>
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    return (
        <>
            <Header type={HeaderType.Other} description="Uurroosters"/>
            <LoadSpinner top={"150px"} isLoading={isLoading} />

            <div className="ps-3 pe-3" style={{height: "calc(100vh - 165px)", overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}>
                {employee ?
                    <div className="p-0 m-0 mt-3 text-start">
                        {getTimeTablePopup()}
                        {getWorkingHoursDiv()}
                        {getTimeTableDiv()}
                        {getDiverseDiv()}
                    </div>
                : ""}
            </div>;
            {getButtonDiv()}
        </>
    );
}
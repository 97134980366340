export enum LeaveRequestPeriod {
    WholeDay,
    DayPart1,
    DayPart2,
    Hours
}

export function translateLeaveRequestPeriod(type: LeaveRequestPeriod): string {
    switch(type) { 
        case LeaveRequestPeriod.WholeDay: {
            return "Hele dag";
        } 
        case LeaveRequestPeriod.DayPart1: {
            return "Dagdeel 1";
        } 
        case LeaveRequestPeriod.DayPart2: {
            return "Dagdeel 2";
        } 
        case LeaveRequestPeriod.Hours: {
            return "Uren";
        } 
        default: { 
            return "";
        } 
    } 
}
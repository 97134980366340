import {
    CardContent,
    Typography,
} from "@mui/material";
import Card from "@mui/material/Card";

function StatusLegendCard() {
    return (
        <Card sx={{ border: "1px solid var(--light-gray-card-border)", boxShadow: 1 }} className="mb-1 p-0">
            <CardContent className="ps-0 pe-0 pb-1 pt-1">
                <Typography variant="subtitle1" component="span" className="me-3">
                    <div style={{
                        width: 4, height: 15, borderRadius: 2, display: "inline-block",
                        marginRight: 3, top: "2px", position: "relative",
                        backgroundColor: "var(--leaverequesttype-bijkancienverlof-color)"
                    }}></div>
                    Aangevraagd
                </Typography>
                <Typography variant="subtitle1" component="span">
                    <div style={{
                        width: 4, height: 15, borderRadius: 2.5, display: "inline-block",
                        marginRight: 3, top: "2px", position: "relative",
                        backgroundColor: "var(--leaverequesttype-verlof-color)"
                    }}></div>
                    Goedgekeurd
                </Typography>
            </CardContent>
        </Card>
    );
}

export default StatusLegendCard;

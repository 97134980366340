export enum PayWay {
    Cash,
    Transfer,
    Electronic,
    AssignmentList
}

export function translatePayWayEnumValue(type: PayWay): string {
    switch(type) { 
        case PayWay.Cash: {
           return "Cash";
        } 
        case PayWay.Transfer: {
            return "Overschrijving";
        } 
        case PayWay.Electronic: {
            return "Elektronische betaling";
        } 
        case PayWay.AssignmentList: {
            return "Opdrachtenlijst";
        }
        default: { 
            return "";
        } 
    } 
}
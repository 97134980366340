export function isDateInPast(date: Date) {
    const currentDate = new Date();
    const dateCopy = new Date(date);
    return dateCopy.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0);
}

export function convertDatesToTimeString(startDate: Date, endDate: Date) {
    const diff = ((endDate.getTime() - startDate.getTime()) / 1000) / 60;
    const minutes = Math.abs(Math.round(diff));
    const hours = Math.trunc(minutes / 60);
    const min = minutes % 60;
    return `${hours}u ${min}m`;
}

export function convertHoursDecimalToTimeString(totalHours: number | null) {
    if (!totalHours)
        return "/";

    const hours = Math.trunc(totalHours);
    const minutes = Math.round((totalHours % 1) * 60);
    return `${hours}u ${minutes}m`;
}

export function convertHoursToTimeString(totalHours: number) {
    const hours = Math.trunc(totalHours);
    const minutes = Math.round((totalHours % 1) * 60);
    const minText = minutes === 0 ? "" : ` ${minutes}m\n`;
    return `${hours}u${minText}`;
}

export function getMondayOfWeek(date: Date) {
    date = new Date(date);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}
export function parseTextToDateFormat(text: string | Date | undefined): string {
    if (text === undefined)
        return "";

    const parsedDate = new Date(text);
    if (parsedDate === null) 
        return "";

    const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
    const date: Date = parsedDate;
    const dateStringFormat: string = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`;
    return dateStringFormat;
}
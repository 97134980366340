import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

export default class BaseApiService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    acquireAccessToken = async () => {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        
        const authResult = await this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}
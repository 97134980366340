import { Tab, Tabs } from "@mui/material";
import HeaderWithReturnFunction from "../../Components/HeaderWithReturnFunction";
import { useHistory } from "react-router-dom";
import useWindowHeight from "../../hooks/useWindowHeight";
import SwipeableViews from "react-swipeable-views";
import { Row } from "reactstrap";
import { useState } from "react";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import AddToHomeScreenImages from "../../Components/Settings/ManualAddAppToHomeScreen/AddToHomeScreenImages";

enum TabsOS {
    Android = 0,
    Apple,
}

const ManualAddAppToHomeScreen = () => {
    const history = useHistory();
    const { windowHeight } = useWindowHeight();
    const [selectedTab, setSelectedTab] = useState<TabsOS>(TabsOS.Android);

    const getButtonDiv = () => {
        return (
            <div className="fixed-bottom" style={{ color: "#2F5FA1" }}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    onChange={(_event, value) => setSelectedTab(value)}
                >
                    <Tab icon={<AndroidIcon />} label="Android" />
                    <Tab icon={<AppleIcon />} label="iOS" />
                </Tabs>
            </div>
        );
    }

    return (
        <>
            <HeaderWithReturnFunction 
                title={"Beginscherm plaatsen"} 
                returnFunction={() => history.goBack()} 
            />

            {getButtonDiv()}

            <div className="m-0" 
                style={{height: `calc(${windowHeight}px - 54px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available"}}
            >
                <SwipeableViews
                    axis={"x"}
                    index={selectedTab}
                    disabled={true}
                    onChangeIndex={(index: number) => setSelectedTab(index)}
                >
                    <div className="ps-3 pe-3">
                        <Row
                            className="align-items-center"
                            style={{
                                height: `calc(${windowHeight}px - 120px)`,
                                overflowY: "auto",
                                overflowX: "hidden",
                                maxHeight: "-webkit-fill-available",
                            }}
                        >
                            <AddToHomeScreenImages
                                isAndroidSection={true}
                            />
                        </Row>
                    </div>
                    <div className="ps-3 pe-3">
                        <Row
                            className="align-items-center"
                            style={{
                                height: `calc(${windowHeight}px - 120px)`,
                                overflowY: "auto",
                                overflowX: "hidden",
                                maxHeight: "-webkit-fill-available",
                            }}
                        >
                            <AddToHomeScreenImages
                                isAndroidSection={false}
                            />
                        </Row>
                    </div>
                </SwipeableViews>
            </div>
        </>
    );
}

export default ManualAddAppToHomeScreen;
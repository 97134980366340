export enum CivilStatus {
    Single,
    Married,
    Widow,
    Divorced,
    Separated,
    Cohabitation,
    LiveTogether
}

export function translateCivilStatusEnumValue(type: CivilStatus): string {
    switch(type) { 
        case CivilStatus.Single: {
           return "Ongehuwd";
        } 
        case CivilStatus.Married: {
            return "Gehuwd";
        } 
        case CivilStatus.Widow: {
            return "Weduwe of weduwenaar";
        } 
        case CivilStatus.Divorced: {
            return "Wettelijk gescheiden";
        } 
        case CivilStatus.Separated: {
            return "Feitelijk of van tafel en bed gescheiden";
        }
        case CivilStatus.Cohabitation: {
            return "Samenwonend";
        }
        case CivilStatus.LiveTogether: {
            return "Wettelijk samenwonend";
        }
        default: { 
            return "";
        } 
    } 
}
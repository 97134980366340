import moment from "moment";
import { LeaveRequest } from "../../../Models/LeaveRequest";
import {
    Alert,
    CardContent,
    Divider,
    Pagination,
    PaginationItem,
    Stack,
    Typography,
} from "@mui/material";
import { Col, Row } from "reactstrap";
import usePagination from "../../../hooks/usePagination";
import { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Card from "@mui/material/Card";
import { convertHoursDecimalToTimeString } from "../../../Helpers/DateHelper";
import { LeaveRequestStatus } from '../../../Models/Enums/LeaveRequestStatus';
import { LeaveRequestPeriod } from "../../../Models/Enums/LeaveRequestPeriod";

type Props = {
    leaveRequests: LeaveRequest[];
    title: string;
    year: number;
};

function LeaveRequestsTable(props: Props) {
    const [page, setPage] = useState(1);
    const { goToPage, currentData, maxPage } = usePagination(props.leaveRequests as any, 5);

    const handleChange = (_event: any, page: any) => {
        setPage(page);
        goToPage(page);
    };

    function getStatusColor(status: LeaveRequestStatus) {
        if (status === LeaveRequestStatus.Accepted)
            return "var(--leaverequesttype-verlof-color)";

        return "var(--leaverequesttype-bijkancienverlof-color)";
    }

    function getStartHours(leaveRequest: LeaveRequest) {
        if (leaveRequest.period === LeaveRequestPeriod.WholeDay)
            return "-";

        return moment(leaveRequest.start).format("HH:mm");
    }

    function getEndHours(leaveRequest: LeaveRequest) {
        if (leaveRequest.period === LeaveRequestPeriod.WholeDay)
            return "-";

        return moment(leaveRequest.end).format("HH:mm");
    }

    return (
        <Card sx={{ border: "1px solid var(--light-gray-card-border)", boxShadow: 1 }} className="mb-2">
            <CardContent style={{ textAlign: "center" }} className="ps-0 pe-0 pb-0">
                <Typography variant="h6" className="mb-2">
                    {props.title} - {props.year}
                </Typography>

                <Divider light={false} />

                {props.leaveRequests?.length > 0 ? (
                    <>
                        {currentData().map((row: LeaveRequest, index: number) => {
                            return (
                                <Row key={index} className="mb-2 d-flex align-items-center">
                                    <Col xs={2}>
                                        <Row className="justify-content-center ps-1">
                                            <span style={{ borderLeft: `4px solid ${getStatusColor(row.status)}` }}>
                                                <Col xs={12}>{moment(row.start).format("DD/MM")}</Col>
                                                <Col xs={12}>{getStartHours(row)}</Col>
                                            </span>
                                        </Row>
                                    </Col>
                                    <Col xs={3}>
                                        <Row className="justify-content-center">
                                            {moment(row.end).format("DD/MM")}
                                        </Row>
                                        <Row className="justify-content-center">
                                            {getEndHours(row)}
                                        </Row>
                                    </Col>
                                    <Col xs={2} className="p-0">
                                        {convertHoursDecimalToTimeString(row.hours)}
                                    </Col>
                                    <Col xs={5} className="align-items-center">
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "1",
                                                WebkitBoxOrient: "vertical",
                                            }}
                                        >
                                            {row.leaveCodeName}
                                        </Typography>
                                    </Col>

                                    <Divider light={false} />
                                </Row>
                            );
                        })}

                        <Stack alignItems="center" className="mb-3">
                            <Pagination
                                count={maxPage}
                                siblingCount={0}
                                page={page}
                                variant="outlined"
                                color="primary"
                                shape="circular"
                                size="medium"
                                onChange={handleChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{
                                            previous: KeyboardArrowLeftIcon,
                                            next: KeyboardArrowRightIcon,
                                        }}
                                        {...item}
                                    />
                                )}
                            />
                        </Stack>
                    </>
                ) : (
                    <Alert className="mb-3" severity="info">
                        <Typography
                            component="span"
                            variant="subtitle2"
                            className="mb-2"
                        >
                            {`Geen ${props.title?.toLowerCase()} gevonden.`}
                        </Typography>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
}

export default LeaveRequestsTable;

/* eslint-disable react-hooks/exhaustive-deps */
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { useHistory, useLocation } from "react-router-dom";
import ApiService from '../Services/ApiService';
import { useEffect } from 'react';
import { Row } from 'reactstrap';
import { Button } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SignUp() {
    const { instance } = useMsal();
    const apiService = useMemo(() => new ApiService(instance), [instance]);
    const history = useHistory();
    const queryParams = useQuery();

    const loginRequest: RedirectRequest = {
        scopes: (process.env.REACT_APP_ADB2C_LOGIN_SCOPES ?? "").split(','),
        extraQueryParameters: { ui_locales: 'nl', id_token_hint: queryParams.get("signuptoken") ?? "" },
        authority: "https://myclb.b2clogin.com/myclb.onmicrosoft.com/B2C_1A_SIGNUP_INVITATION"
    };

    const { error, result } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    useEffect(() => {
        if (!result)
            return;

        const personId = queryParams.get("personid");
        if (!personId)
            return;

        const reinvite = queryParams.get("reinvite")?.toLowerCase() === "true";
        const email = queryParams.get("email") ?? "";

        apiService.createUser(Number.parseInt(personId), result.uniqueId, reinvite, email).then(doesUserExist => {
            if (doesUserExist)
                toast.error("Er bestaat al een gebruiker voor deze werknemer.");
        }).catch((error: Error) => {
            toast.error(error.message);
        }).finally(() => {
            instance.logout();
        });
    }, [result]);

    useEffect(() => {
        if (error !== null)
            toast.error(error.message);
    }, [error]);

    function goToHome() {
        history.push("/");
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Row>
                    <Button variant="contained" style={{ backgroundColor: "var(--blue-color)" }} fullWidth onClick={goToHome}>
                        <ArrowBack fontSize="medium" />
                    </Button>
                </Row>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Row>
                    <Button variant="contained" style={{ backgroundColor: "var(--blue-color)" }} fullWidth onClick={goToHome}>
                        <ArrowBack fontSize="medium" />
                    </Button>
                </Row>
            </UnauthenticatedTemplate>
        </>
    );
}
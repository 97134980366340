export enum Transportation {
    OwnTransportation,
    Train,
    Tram,
    Bus,
    Bicycle,
    OnFoot,
    OrganizedByEmployer
}

export function translateTransportationEnumValue(type: Transportation): string {
    switch(type) { 
        case Transportation.OwnTransportation: {
           return "Eigen vervoer";
        } 
        case Transportation.Train: {
            return "Trein";
        } 
        case Transportation.Tram: {
            return "Tram";
        } 
        case Transportation.Bus: {
            return "Bus";
        } 
        case Transportation.Bicycle: {
            return "Fiets";
        } 
        case Transportation.OnFoot: {
            return "Te voet";
        } 
        case Transportation.OrganizedByEmployer: {
            return "Georganiseerd door werkgever";
        } 
        default: { 
            return "";
        } 
    } 
}
import { Views } from "react-big-calendar";
import { Col, Row } from "reactstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UndoIcon from '@mui/icons-material/Undo';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Button } from "@mui/material";

interface Props {
    goToPreviousDate: Function;
    goToCurrentDate: Function;
    goToMonthView: Function;
    goToNextDate: Function;
    selectedCalenderUnit: string;
}

const CalenderFooterNavigation = (props: Props) => {
    return (
        <Row>
            <Col xs={5} className="m-0 p-0">
                <Button variant="text" color="inherit" fullWidth onClick={() => props.goToPreviousDate()}>
                    <ArrowBackIcon color="action" fontSize="medium" />
                </Button>
            </Col>
            {props.selectedCalenderUnit === Views.MONTH ?
                <Col xs={2} className="m-0 p-0">
                    <Button variant="text" color="inherit" fullWidth onClick={() => props.goToCurrentDate()}>
                        <CalendarTodayIcon color="action" fontSize="medium" />
                    </Button>
                </Col>
                :
                <Col xs={2} className="m-0 p-0">
                    <Button variant="text" color="inherit" fullWidth onClick={() => props.goToMonthView()}>
                        <UndoIcon color="action" fontSize="medium" />
                    </Button>
                </Col>
            }
            <Col xs={5} className="m-0 p-0">
                <Button variant="text" color="inherit" fullWidth onClick={() => props.goToNextDate()}>
                    <ArrowForwardIcon color="action" fontSize="medium" />
                </Button>
            </Col>
        </Row>
    )
}

export default CalenderFooterNavigation;
import { useMsal } from "@azure/msal-react";
import {
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Box,
    Typography,
    CardContent,
    CardMedia,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import ClbLogo from "../../Assets/ClbGroupLogoBlue.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";
import HomeIcon from "@mui/icons-material/Home";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "./Drawer.css";
import { useEffect, useMemo, useState } from "react";
import EmployeeService from "../../Services/EmployeeService";
import { EmployeeGeneral } from "../../Models/EmployeeGeneral";
import LocalStorageService from "../../Services/LocalStorageService";
import UserProfile from "../../Assets/UserProfile.png";
import { useDispatch, useSelector } from "react-redux";
import { drawerActions } from "../../store/DrawerSlicer";
import { removePushNotificationUserId, setPushNotificationUserId } from "../../Helpers/OneSignalNotificationsHelper";
import { LoginUser } from "../../Models/LoginUser";
import SettingsIcon from '@mui/icons-material/Settings';

export function DrawerSideBar() {
    const { instance } = useMsal();
    const employeeService = useMemo(() => new EmployeeService(instance), [instance]);
    const [employeeName, setEmployeeName] = useState<string>("");
    const [employeeFunction, setEmployeeFunction] = useState<string>("");
    const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState<boolean>(false);
    const isOpen = useSelector((state: any) => state.drawer.isOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        const loginUser = LocalStorageService.getLoginUser();
        if (loginUser) {
            setEmployeeName(loginUser.employeeName);
            setEmployeeFunction(loginUser.employeeFunction);

            if (loginUser.employeeId)
                setPushNotificationUserId(loginUser.employeeId);

            return;
        }

        employeeService
            .getEmployeeGeneral()
            .then((employee: EmployeeGeneral) => {
                const loginUser: LoginUser = {
                    employeeName: employee.fullname?.toLowerCase(),
                    employeeFunction: employee.functionDescription?.toLowerCase(),
                    employeeId: employee.id,
                };
                LocalStorageService.setLoginUser(loginUser);
                setPushNotificationUserId(employee.id);

                setEmployeeName(employee.fullname?.toLowerCase());
                setEmployeeFunction(employee.functionDescription?.toLowerCase());
            })
            .catch((error: Error) => {
                toast.error(error.message);
            });
    }, [employeeService]);

    const LogoutHandler = () => {
        setIsLogoutPopupOpen(true);
        dispatch(drawerActions.toggle());
        
        try {
            LocalStorageService.clearLoginUser();
            removePushNotificationUserId();
            instance.logout();
        } catch (err) {
            toast.error("Uitloggen mislukt.");
        }
    };

    function getUserProfileCard() {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    textTransform: "capitalize",
                    pb: 0,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        pl: 1,
                        pt: 2,
                        justifyContent: "center",
                    }}
                >
                    <CardMedia
                        component="img"
                        sx={{ width: 130 }}
                        image={UserProfile}
                        alt="Gebruiker"
                    />
                </Box>
                <CardContent
                    sx={{ flex: "1 0 auto", pt: 0, textAlign: "center", "&:last-child": { pb: 2 } }}
                >
                    <Typography component="div" variant="h5">
                        {employeeName}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {employeeFunction}
                    </Typography>
                </CardContent>
            </Box>
        );
    }

    function logoutPopup() {
        return (
            <Dialog
                open={isLogoutPopupOpen}
                onClose={() => setIsLogoutPopupOpen(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Afmelden</DialogTitle>
                <DialogContent>Bent u zeker dat u wil afmelden?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsLogoutPopupOpen(false)}>Annuleer</Button>
                    <Button onClick={LogoutHandler}>Ja</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const drawer = (
        <div>
            {getUserProfileCard()}
            <Divider />
            <List>
                <NavLink
                    className="nav-link p-0"
                    to={"/Home"}
                    onClick={() => dispatch(drawerActions.toggle())}
                    key="Home"
                >
                    <ListItemButton>
                        <ListItemIcon className="color-blue ms-2">
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </NavLink>
                <NavLink
                    className="nav-link p-0"
                    to={"/AbsenceRegistrationCalender"}
                    onClick={() => dispatch(drawerActions.toggle())}
                    key="Verlofaanvraag"
                >
                    <ListItemButton>
                        <ListItemIcon className="color-blue ms-2">
                            <DateRangeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Verlofaanvraag" />
                    </ListItemButton>
                </NavLink>
                <NavLink
                    className="nav-link p-0"
                    to={"/Settings"}
                    onClick={() => dispatch(drawerActions.toggle())}
                    key="Instellingen"
                >
                    <ListItemButton>
                        <ListItemIcon className="color-blue ms-2">
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Instellingen" />
                    </ListItemButton>
                </NavLink>
                <NavLink
                    className="nav-link p-0"
                    to={"/"}
                    onClick={() => setIsLogoutPopupOpen(true)}
                    key="Afmelden"
                >
                    <ListItemButton>
                        <ListItemIcon className="color-blue ms-2">
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText color="white" primary="Afmelden" />
                    </ListItemButton>
                </NavLink>
            </List>
            <Divider />
            <div style={{ position: "absolute", bottom: "0", marginLeft: 60 }}>
                <img src={ClbLogo} alt="CLB Group" style={{ width: "8em" }} className="mb-3" />
            </div>
        </div>
    );

    return (
        <>
            {logoutPopup()}
            <Drawer
                PaperProps={{
                    sx: {
                        color: "black",
                        marginTop: 0,
                    },
                }}
                variant="temporary"
                open={isOpen}
                onClose={() => dispatch(drawerActions.toggle())}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: "250px", marginTop: 0 },
                }}
            >
                {drawer}
            </Drawer>
        </>
    );
}

export enum SpouseProfession {
    Handworker,
    Servant,
    Employee,
    SelfEmployed,
    Miner,
    Sailor,
    CivilServant,
    Other,
    Nil
}

export function translateSpouseProfessionEnumValue(type: SpouseProfession): string {
    switch(type) { 
        case SpouseProfession.Handworker: {
           return "Handarbeid(er) (ster)";
        } 
        case SpouseProfession.Servant: {
            return "Dienstbode";
        } 
        case SpouseProfession.Employee: {
            return "Hoofdarbeid(er) (ster)";
        } 
        case SpouseProfession.SelfEmployed: {
            return "Zelfstandigen";
        } 
        case SpouseProfession.Miner: {
            return "Mijnwerker";
        }
        case SpouseProfession.Sailor: {
            return "Zeeman der koopvaardij";
        }
        case SpouseProfession.CivilServant: {
            return "Rijksbeambte";
        }
        case SpouseProfession.Other: {
            return "Andere / Onbekend";
        }
        case SpouseProfession.Nil: {
            return "Nihil";
        }
        default: { 
            return "";
        } 
    } 
}
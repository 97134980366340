
export enum LeaveRequestStatus {
    Pending,
    Accepted,
    Declined,
    Deleted,
    Collective
}
export function translateLeaveRequestStatus(type: LeaveRequestStatus): string {
    switch (type) {
        case LeaveRequestStatus.Pending: {
            return "Aangevraagd";
        }
        case LeaveRequestStatus.Accepted: {
            return "Goedgekeurd";
        }
        case LeaveRequestStatus.Declined: {
            return "Geweigerd";
        }
        case LeaveRequestStatus.Collective: {
            return "Collectief";
        }
        default: {
            return "";
        }
    }
}

export function getLeaveRequestStatusOpacity(type: LeaveRequestStatus): number {
    switch (type) {
        case LeaveRequestStatus.Pending: {
            return 0.6;
        }
        case LeaveRequestStatus.Accepted: {
            return 1;
        }
        default: {
            return 1;
        }
    }
}
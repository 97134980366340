import OneSignal from 'react-onesignal';
import LocalStorageService from '../Services/LocalStorageService';

export const initPushNotifications = () => {
    OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID ?? "",
    });
}

export const isPushNotificationsEnabled = async () => {
    return await OneSignal.isPushNotificationsEnabled();
}

export const registerForPushNotifications = async () => {
    await OneSignal.registerForPushNotifications();
}

// Set external user id so that OneSignal can target the correct device
export const setPushNotificationUserId = (employeeId: number | null) => {
    if (!employeeId)
        return;

    OneSignal.setExternalUserId(employeeId.toString());
}

export const removePushNotificationUserId = () => {
    OneSignal.removeExternalUserId();
}

export const getPushNotificationUserId = async () => {
    return await OneSignal.getExternalUserId();
}

export const setPushNotificationsUserIdIfEnabled = async () => {
    const isEnabled = await OneSignal.isPushNotificationsEnabled();
    if (!isEnabled)
        return;

    const loginUser = LocalStorageService.getLoginUser();
    if (loginUser)
        setPushNotificationUserId(loginUser.employeeId);
}
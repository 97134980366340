import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';

interface Props {
    top: string;
    isLoading: boolean;
}

export function LoadSpinner(props: Props) {
    function getLoadSpinner() {
        return (
            <ClipLoader 
                color="gray"
                loading={props.isLoading}
                css={
                    css`
                    display: block;
                    position: absolute;
                    left: calc(50% - 18px);
                    top: ${props.top}`
                }
                size={35} />
        );
    }

    return (
        <>
            {getLoadSpinner()}
        </>
    );
}
export enum PartTimeDetail {
    Fixed,
    Variable
}

export function translatePartTimeDetailEnumValue(type: PartTimeDetail): string {
    switch(type) { 
        case PartTimeDetail.Fixed: {
           return "Vast";
        } 
        case PartTimeDetail.Variable: {
            return "Variabel";
        } 
        default: { 
            return "";
        } 
    } 
}
import { PickerView } from 'antd-mobile';
import { DaySchedule } from '../../Models/DaySchedule';
import { useCallback, useEffect, useState } from 'react';
import { PickerColumn, PickerValue } from 'antd-mobile/es/components/picker-view';
import { LeaveRequestPeriod } from '../../Models/Enums/LeaveRequestPeriod';

interface Props {
    value: Date;
    period: LeaveRequestPeriod;
    previousPeriod: LeaveRequestPeriod;
    daySchedule: DaySchedule | undefined;
    readOnly: boolean;
    handleUpdate: Function;
}

const ScrollableTimePicker = (props: Props) => {
    const [value, setValue] = useState<(number)[]>([]);
    const [columns, setColumns] = useState<PickerColumn[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getHoursOption = useCallback((min: number, max: number) => {
        const hours = [];
        for (let i = min; i < max + 1; i++) {
            hours.push({ label: i, value: i })
        }
        return hours;
    }, []);

    useEffect(() => {
        if (props.value) {
            const date = new Date(props.value);
            setValue([date.getHours() ?? 0, date.getMinutes() ?? 59]);
        }
    }, [props.value]);

    useEffect(() => {
        // Set minimum and maximum hours by period
        let minhours = 0;
        let maxhours = 23;
        const daySchedule = props.daySchedule;

        if (!daySchedule)
            return;

        if (props.period === LeaveRequestPeriod.DayPart1) {
            const start = parseFloat(props.daySchedule?.amStart.replace(",", ".") ?? "1");
            const end = parseFloat(props.daySchedule?.amEnd.replace(",", ".") ?? "1");

            minhours = Math.trunc(start);
            maxhours = Math.trunc(end);
        } else if (props.period === LeaveRequestPeriod.DayPart2) {
            const start = parseFloat(props.daySchedule?.pmStart.replace(",", ".") ?? "1");
            const end = parseFloat(props.daySchedule?.pmEnd.replace(",", ".") ?? "1");

            minhours = Math.trunc(start);
            maxhours = Math.trunc(end);
        } else if (props.period === LeaveRequestPeriod.Hours) {
            let startTime = 0;
            if (daySchedule.hasAmTime)
                startTime = parseFloat(daySchedule.amStart.replace(",", "."));

            let endTime = 23;
            if (daySchedule.hasPmTime) {
                endTime = parseFloat(daySchedule.pmEnd.replace(",", "."));
            } else if (daySchedule.hasAmTime) {
                endTime = parseFloat(daySchedule.amEnd.replace(",", "."));
            }

            const startHours = Math.trunc(startTime);
            const endHours = Math.trunc(endTime);

            minhours = startHours;
            maxhours = endHours;
        }

        const columns = [
            getHoursOption(minhours, maxhours),
            getHoursOption(0, 59),
        ];
        setColumns(columns);
    }, [props.daySchedule, props.period, getHoursOption]);

    const updateValue = (newValue: any[]) => {
        if (isLoading && props.previousPeriod === LeaveRequestPeriod.WholeDay) {
            setIsLoading(false);
            return;
        }

        let date = new Date(props.value);
        date = new Date(date.setHours(newValue[0]));
        date = new Date(date.setMinutes(newValue[1]));

        props.handleUpdate(date);
    }

    return (
        <PickerView
            value={value}
            style={{
                height: "150px",
                zIndex: 0,
                border: "1px solid lightgray",
                borderRadius: "4px"
            }}
            columns={columns}
            mouseWheel={true}
            onChange={(value: PickerValue[]) => !props.readOnly && updateValue(value)}
        />
    );
}

export default ScrollableTimePicker;
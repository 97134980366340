import { useState, useEffect, useMemo } from 'react';
import {
    Alert, AppBar, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem,
    Select, Stack, TextField, Toolbar, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from "react-toastify";
import { LeaveRequestPeriod, translateLeaveRequestPeriod } from "../../Models/Enums/LeaveRequestPeriod";
import { Col, Row } from 'reactstrap';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LeaveRequestStatus, translateLeaveRequestStatus } from '../../Models/Enums/LeaveRequestStatus';
import { LeaveRequest } from '../../Models/LeaveRequest';
import { useHistory, useLocation } from 'react-router-dom';
import AbsenceRegistrationService from '../../Services/AbsenceRegistrationService';
import { useMsal } from '@azure/msal-react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { convertDatesToTimeString, convertHoursToTimeString, isDateInPast } from '../../Helpers/DateHelper';
import { BalanceCounter } from '../../Models/BalanceCounter';
import moment from 'moment';
import { WeeksSchedule } from '../../Models/WeeksSchedule';
import { LoadSpinner } from '../../Components/LoadSpinner';
import useWindowHeight from '../../hooks/useWindowHeight';
import { DonutChartCard } from '../../Components/AbsenceRegistrationTool/DonutChartCard';
import ScrollableTimePicker from '../../Components/AbsenceRegistrationTool/ScrollableTimePicker';
import { DaySchedule } from '../../Models/DaySchedule';
import { isFirstWeekActiveOfSchedule } from '../../Helpers/AbsenceRegistrationHelper';
import LeaveCode from '../../Models/LeaveCode';
import { SelectChangeEvent } from '@mui/material/Select';

export default function DetailsLeaveRequest() {
    const history = useHistory();
    const location = useLocation();
    const { instance } = useMsal();
    const states = location.state as any;
    const leaveRequestState = states.leaveRequest as LeaveRequest;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBalanceCountersLoading, setIsBalanceCountersLoading] = useState<boolean>(false);
    const [leaveCodes, setLeaveCodes] = useState<LeaveCode[]>([]);
    const [balanceCounters, setBalanceCounters] = useState<BalanceCounter[]>();
    const [isUpdateConfimationDialogOpen, setIsUpdateConfimationDialogOpen] = useState<boolean>(false);
    const [isRemoveConfimationDialogOpen, setIsRemoveConfimationDialogOpen] = useState<boolean>(false);
    const [leaveRequest] = useState<LeaveRequest>(leaveRequestState);
    const [period, setPeriod] = useState<LeaveRequestPeriod>(leaveRequestState.period);
    const [previousPeriod, setPreviousPeriod] = useState<LeaveRequestPeriod>(leaveRequestState.period);
    const [leaveRequestCode, setLeaveRequestCode] = useState<string>(leaveRequestState.code);
    const [oldStart, setOldStart] = useState<Date>(new Date(leaveRequestState.start));
    const [oldEnd, setOldEnd] = useState<Date>(new Date(leaveRequestState.end));
    const [leaveRequestStart, setLeaveRequestStart] = useState<Date>(new Date(leaveRequest.start));
    const [leaveRequestEnd, setLeaveRequestEnd] = useState<Date>(new Date(leaveRequest.end));
    const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const absenceRegistrationService = useMemo(() => new AbsenceRegistrationService(instance), [instance]);
    const { windowHeight } = useWindowHeight();
    const [startTime, setStartTime] = useState<any>(moment(new Date(leaveRequestState.start)));
    const [endTime, setEndTime] = useState<any>(moment(new Date(leaveRequestState.end)));
    const [weeksSchedules, setWeeksSchedules] = useState<WeeksSchedule[]>([]);
    const [currentWeeksSchedule, setCurrentWeeksSchedule] = useState<WeeksSchedule>();
    const [daySchedule, setDaySchedule] = useState<DaySchedule>();
    const [selectedDay, setSelectedDay] = useState<number>();
    const [amountOfHours, setAmountOfHours] = useState<string>();
    const [leaveTypeFilter, setLeaveTypeFilter] = useState<string>();

    const today = new Date();

    useEffect(() => {
        const leaveCodes = states.leaveCodes as LeaveCode[];
        const isMonthClosed = states.isMonthClosed as boolean;
        setLeaveCodes(!leaveCodes ? [] : leaveCodes);
        setIsEditable(!isDateInPast(leaveRequestState.start) && (!leaveRequestState.isSendToPrisma as boolean && !isMonthClosed && leaveRequestState.scheduleViaApp === true));

        getBalanceCountersAndWeeksSchedulesByYear(leaveRequestState.start);
    }, []);

    useEffect(() => {
        if (!leaveRequestStart || !leaveRequestEnd)
            return;

        leaveRequestStart.setSeconds(0);
        leaveRequestEnd.setDate(leaveRequestStart.getDate());
        leaveRequestEnd.setMonth(leaveRequestStart.getMonth());
        leaveRequestEnd.setFullYear(leaveRequestStart.getFullYear());
        leaveRequestEnd.setSeconds(0);

        if (period === LeaveRequestPeriod.WholeDay) {
            leaveRequestStart.setHours(8);
            leaveRequestStart.setMinutes(0);
            leaveRequestEnd.setHours(16);
            leaveRequestEnd.setMinutes(0);
            setLeaveRequestStart(new Date(leaveRequestStart));
            setLeaveRequestEnd(new Date(leaveRequestEnd));
        } else if (period === LeaveRequestPeriod.DayPart1) {
            let start = new Date(leaveRequestStart);
            setTimeForDayPart1(start, weeksSchedules);
        } else if (period === LeaveRequestPeriod.DayPart2) {
            let start = new Date(leaveRequestStart);
            setTimeForDayPart2(start, weeksSchedules);
        } else {
            let start = new Date(leaveRequestStart);
            setTimeForHours(start, weeksSchedules);
        }
    }, [period]);

    function calculateHoursBetweenStartAndEnd(start: Date | undefined, end: Date | undefined, currentWeeksSchedule: WeeksSchedule | undefined, selectedDay: number | undefined) {
        if (!currentWeeksSchedule || selectedDay == null || !start || !end)
            return;

        const isFirstWeek = isFirstWeekActiveOfSchedule(start, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];

        let startDate = new Date(start);
        let endDate = new Date(end);

        if (period === LeaveRequestPeriod.WholeDay) {
            const hours = dayHours.hours;
            setAmountOfHours(convertHoursToTimeString(hours));
        } else if (period === LeaveRequestPeriod.DayPart1) {
            const start = parseFloat(dayHours.amStart.replace(",", "."));
            const end = parseFloat(dayHours.amEnd.replace(",", "."));

            startDate.setHours(Math.trunc(start));
            startDate.setMinutes((start % 1) * 100);
            endDate.setHours(Math.trunc(end));
            endDate.setMinutes((end % 1) * 100);

            setAmountOfHours(convertDatesToTimeString(startDate, endDate));
        } else if (period === LeaveRequestPeriod.DayPart2) {
            const start = parseFloat(dayHours.pmStart.replace(",", "."));
            const end = parseFloat(dayHours.pmEnd.replace(",", "."));

            startDate.setHours(Math.trunc(start));
            startDate.setMinutes((start % 1) * 100);
            endDate.setHours(Math.trunc(end));
            endDate.setMinutes((end % 1) * 100);

            setAmountOfHours(convertDatesToTimeString(startDate, endDate));
        } else if (period === LeaveRequestPeriod.Hours) {
            if (dayHours.hasAmTime && dayHours.hasPmTime) {
                const restEnd = parseFloat(dayHours.pmStart.replace(",", "."));
                const restStart = parseFloat(dayHours.amEnd.replace(",", "."));
                let rest = 0;

                const hoursStart = (startDate.getHours() + (startDate.getMinutes() / 100));
                const hoursEnd = (endDate.getHours() + (endDate.getMinutes() / 100));

                if (hoursStart <= restStart && hoursEnd >= restEnd) {
                    rest = restEnd - restStart;
                } else if (hoursStart < restStart && hoursEnd > restStart && hoursEnd < restEnd) {
                    rest = hoursEnd - restStart;
                } else if (hoursStart > restStart && hoursStart < restEnd && hoursEnd > restEnd) {
                    rest = restEnd - hoursStart;
                } else if ((hoursStart <= restStart && hoursEnd <= restStart) || (hoursStart >= restEnd && hoursEnd >= restEnd)) {
                    rest = 0;
                } else {
                    setAmountOfHours("0u 0m");
                    return;
                }

                endDate.setHours(endDate.getHours() - Math.trunc(rest));
                endDate.setMinutes(endDate.getMinutes() - Math.trunc(Math.round((rest % 1) * 100)));
            }

            setAmountOfHours(convertDatesToTimeString(startDate, endDate));
        }
    }

    function getBalanceCountersAndWeeksSchedulesByYear(date: Date) {
        setIsBalanceCountersLoading(true);

        Promise.all([
            absenceRegistrationService.getBalanceCountersByYear(date),
            absenceRegistrationService.getWeeksSchedulesByYear(date),
            absenceRegistrationService.getLeaveCodes()
        ]).then((values: any) => {
            setBalanceCounters(values[0]);
            setLeaveCodes(values[2]);
            setWeeksSchedules(values[1]);
            getCurrentWeeksSchedule(values[1], date);

            calculateHoursBetweenStartAndEnd(leaveRequestState.start, leaveRequestState.end,
                getActiveWeeksSchedule(values[1], date), getDayOfWeek(date));
        }).finally(() => {
            setIsBalanceCountersLoading(false);
        });
    }

    function getCurrentWeeksSchedule(weeksSchedules: WeeksSchedule[], date: Date) {
        const activeWeeksSchedule = weeksSchedules.find(w => {
            const startDate = w.startDate !== null ? new Date(w.startDate) : null;
            const endDate = w.endDate !== null ? new Date(w.endDate) : null;
            return (startDate !== null && startDate <= date) && (endDate === null || endDate > date);
        });

        const selectedDay = getDayOfWeek(date);
        setSelectedDay(selectedDay);
        setCurrentWeeksSchedule(activeWeeksSchedule);

        if (!activeWeeksSchedule)
            return;

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, activeWeeksSchedule);
        const dayHours = isFirstWeek ? activeWeeksSchedule.week1Days[selectedDay] : activeWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);
    }

    function getActiveWeeksSchedule(weeksSchedules: WeeksSchedule[], date: Date) {
        const activeWeeksSchedule = weeksSchedules.find(w => {
            const startDate = w.startDate !== null ? new Date(w.startDate) : null;
            const endDate = w.endDate !== null ? new Date(w.endDate) : null;
            return (startDate !== null && startDate <= date) && (endDate === null || endDate > date);
        });

        return activeWeeksSchedule;
    }

    function getDayOfWeek(date: Date) {
        const day = date.getDay();
        return day === 0 ? 6 : day - 1;
    }

    function setTimeForHours(date: Date, weeksSchedules: WeeksSchedule[]) {
        if (!weeksSchedules || weeksSchedules.length === 0) {
            setLeaveRequestStart(new Date(date));
            return;
        }

        const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, date);
        const selectedDay = getDayOfWeek(date);
        setCurrentWeeksSchedule(currentWeeksSchedule);
        setSelectedDay(selectedDay);

        if (!currentWeeksSchedule) {
            setLeaveRequestStart(new Date(date));
            return;
        }

        let startDate = new Date(date);
        let endDate = new Date(date);

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);

        let startTime = 8;
        if (dayHours.hasAmTime)
            startTime = parseFloat(dayHours.amStart.replace(",", "."));

        let endTime = 8 + (dayHours.hasHours ? convertHoursDecimalToTime(dayHours.hours) : 8);
        if (dayHours.hasPmTime) {
            endTime = parseFloat(dayHours.pmEnd.replace(",", "."));
        } else if (dayHours.hasAmTime) {
            endTime = parseFloat(dayHours.amEnd.replace(",", "."));
        }

        startDate.setHours(Math.trunc(startTime));
        startDate.setMinutes(Math.round((startTime % 1) * 100));
        setStartTime(new Date(startDate));
        setLeaveRequestStart(startDate);

        endDate.setHours(Math.trunc(endTime));
        endDate.setMinutes(Math.round((endTime % 1) * 100));
        endDate.setDate(date.getDate());
        endDate.setMonth(date.getMonth());
        endDate.setFullYear(date.getFullYear());
        setEndTime(new Date(endDate));
        setLeaveRequestEnd(endDate);

        calculateHoursBetweenStartAndEnd(startDate, new Date(endDate), currentWeeksSchedule, selectedDay);
    }

    // 2.5 -> 2u30m
    function convertHoursDecimalToTime(totalHours: number) {
        const hours = Math.trunc(totalHours);
        const minutes = (endTime % 1) * 0.60;
        return (hours + minutes);
    }

    function setTimeForDayPart1(date: Date, weeksSchedules: WeeksSchedule[]) {
        if (!weeksSchedules || weeksSchedules.length === 0) {
            setLeaveRequestStart(new Date(date));
            return;
        }

        const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, date);
        const selectedDay = getDayOfWeek(date);
        setCurrentWeeksSchedule(currentWeeksSchedule);
        setSelectedDay(selectedDay);

        if (!currentWeeksSchedule) {
            setLeaveRequestStart(new Date(date));
            return;
        }

        if (!currentWeeksSchedule.week1Days[selectedDay].hasAmTime) {
            setPeriod(LeaveRequestPeriod.Hours);
            return;
        }

        let startDate = new Date(date);
        let endDate = new Date(date);

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);

        const startTime = parseFloat(dayHours.amStart.replace(",", "."));
        const endTime = parseFloat(dayHours.amEnd.replace(",", "."));

        startDate.setHours(Math.trunc(startTime));
        startDate.setMinutes(Math.round((startTime % 1) * 100));
        setStartTime(new Date(startDate));
        setLeaveRequestStart(startDate);

        endDate.setHours(Math.trunc(endTime));
        endDate.setMinutes(Math.round((endTime % 1) * 100));
        endDate.setDate(date.getDate());
        endDate.setMonth(date.getMonth());
        endDate.setFullYear(date.getFullYear());
        setEndTime(new Date(endDate));
        setLeaveRequestEnd(endDate);

        calculateHoursBetweenStartAndEnd(startDate, new Date(endDate), currentWeeksSchedule, selectedDay);
    }

    function setTimeForDayPart2(date: Date, weeksSchedules: WeeksSchedule[]) {
        if (!weeksSchedules || weeksSchedules.length === 0) {
            setLeaveRequestStart(new Date(date));
            return;
        }

        const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, date);
        const selectedDay = getDayOfWeek(date);
        setCurrentWeeksSchedule(currentWeeksSchedule);
        setSelectedDay(selectedDay);

        if (!currentWeeksSchedule) {
            setLeaveRequestStart(new Date(date));
            return;
        }

        if (!currentWeeksSchedule.week1Days[selectedDay].hasPmTime) {
            setPeriod(LeaveRequestPeriod.Hours);
            return;
        }

        let startDate = new Date(date);
        let endDate = new Date(date);

        const isFirstWeek = isFirstWeekActiveOfSchedule(date, currentWeeksSchedule);
        const dayHours = isFirstWeek ? currentWeeksSchedule.week1Days[selectedDay] : currentWeeksSchedule.week2Days[selectedDay];
        setDaySchedule(dayHours);

        const startTime = parseFloat(dayHours.pmStart.replace(",", "."));
        const endTime = parseFloat(dayHours.pmEnd.replace(",", "."));

        startDate.setHours(Math.trunc(startTime));
        startDate.setMinutes(Math.round((startTime % 1) * 100));
        setStartTime(new Date(startDate));
        setLeaveRequestStart(startDate);

        endDate.setHours(Math.trunc(endTime));
        endDate.setMinutes(Math.round((endTime % 1) * 100));
        endDate.setDate(date.getDate());
        endDate.setMonth(date.getMonth());
        endDate.setFullYear(date.getFullYear());
        setEndTime(new Date(endDate));
        setLeaveRequestEnd(endDate);

        calculateHoursBetweenStartAndEnd(startDate, new Date(endDate), currentWeeksSchedule, selectedDay);
    }

    async function handleOnLeaveRequestStartChange(newEventStart: Date) {
        if (!newEventStart || !leaveRequestEnd)
            return;

        let startDate = new Date(newEventStart.valueOf())
        let endDate = new Date(leaveRequestEnd.valueOf())
        let currentWeeksSchedules = weeksSchedules;
        setLeaveRequestStart(startDate);

        if (oldStart?.getFullYear() !== startDate.getFullYear()) {
            setLeaveRequestStart(oldStart);
            setLeaveRequestEnd(oldEnd);
            toast.error("U kan geen verlofaanvraag wijzigen naar een ander jaar.");
            return;
        } else {
            setOldStart(startDate);
            setOldEnd(endDate);
        }

        if (startDate > leaveRequestEnd) {
            endDate.setDate(leaveRequestStart.getDate());
            endDate.setMonth(leaveRequestStart.getMonth());
            endDate.setFullYear(leaveRequestStart.getFullYear());

            if (period === LeaveRequestPeriod.WholeDay) {
                endDate.setHours(16);
                endDate.setMinutes(0);
                endDate.setSeconds(0);
            }

            setEndTime(moment(endDate));
            setLeaveRequestEnd(endDate);
        }

        if (period === LeaveRequestPeriod.DayPart1) {
            setTimeForDayPart1(startDate, currentWeeksSchedules);
            return;
        } else if (period === LeaveRequestPeriod.DayPart2) {
            setTimeForDayPart2(startDate, currentWeeksSchedules);
            return;
        } else if (period === LeaveRequestPeriod.Hours) {
            setTimeForHours(startDate, currentWeeksSchedules);
            return;
        } else {
            // Whole day
            const currentWeeksSchedule = getActiveWeeksSchedule(weeksSchedules, startDate);
            const selectedDay = getDayOfWeek(startDate);
            setCurrentWeeksSchedule(currentWeeksSchedule);
            setSelectedDay(selectedDay);
        }
    }

    function goBackToAbsenceRegistrationCalender(): void {
        history.push({
            pathname: '/AbsenceRegistrationCalender',
            state: {
                goToDate: leaveRequest?.start
            }
        });
    }

    function updateLeaveRequest() {
        if (!leaveRequest || !leaveRequestStart || !leaveRequestEnd || !leaveRequestCode) {
            return;
        }

        leaveRequestStart.setSeconds(0);
        leaveRequestEnd.setSeconds(0);
        leaveRequestStart.setMilliseconds(0);
        leaveRequestEnd.setMilliseconds(0);

        const updatedLeaveRequest = { ...leaveRequest };

        updatedLeaveRequest.start = leaveRequestStart;
        updatedLeaveRequest.end = leaveRequestEnd;
        updatedLeaveRequest.code = leaveRequestCode;
        updatedLeaveRequest.period = period;
        updatedLeaveRequest.leaveCodeName = leaveCodes.find(r => r.code === leaveRequestCode)?.name;

        setIsUpdateConfimationDialogOpen(false);
        setIsLoading(true);

        absenceRegistrationService.createOrUpdateLeaveRequest(updatedLeaveRequest).then((isSuccess: boolean) => {
            if (isSuccess) {
                toast.success("Uw aanvraag is gewijzigd!");
                goBackToAbsenceRegistrationCalender();
            }
        }).catch((error: Error) => {
            toast.error(error.message);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    function checkIfFormIsValidAndOpenUpdateDialog() {
        const hasWeekSchedule = weeksSchedules && weeksSchedules.length >= 0 && currentWeeksSchedule;
        if (!hasWeekSchedule) {
            toast.error("Er is geen uurrooster ingesteld voor deze datum");
            return;
        }

        if (!leaveRequestStart || !leaveRequestEnd || leaveRequestCode === "") {
            toast.error("Alle verplichte velden moeten ingevuld zijn.");
            return;
        }

        const start = moment(startTime);
        const end = moment(endTime);
        if (period === LeaveRequestPeriod.Hours && (!start || !start.isValid() || !end || !end.isValid())) {
            toast.error("Gelieve een geldige start- en eindtijd in te geven.");
            return;
        }

        if (leaveRequestStart >= leaveRequestEnd) {
            toast.error("De einddatum moet groter zijn als de startdatum.");
            return;
        }

        if (isDateInPast(leaveRequestStart)) {
            toast.error("U kan geen verlofaanvraag wijzigen naar het verleden.");
            return;
        }

        setIsUpdateConfimationDialogOpen(true);
    }

    function handleStartTimeChange(value: Date) {
        if (value) {
            const startTime = value;
            const start = moment(leaveRequestStart).toDate();
            start.setHours(startTime.getHours());
            start.setMinutes(startTime.getMinutes());
            setLeaveRequestStart(start);

            calculateHoursBetweenStartAndEnd(start, leaveRequestEnd, currentWeeksSchedule, selectedDay);
        }

        setStartTime(value);
    }

    function handleEndTimeChange(value: Date) {
        if (value) {
            const endTime = value;
            const end = moment(leaveRequestEnd).toDate();
            end.setHours(endTime.getHours());
            end.setMinutes(endTime.getMinutes());
            setLeaveRequestEnd(end);

            calculateHoursBetweenStartAndEnd(leaveRequestStart, end, currentWeeksSchedule, selectedDay);
        }

        setEndTime(value);
    }

    function removeLeaveRequest() {
        if (!leaveRequest)
            return;

        setIsLoading(true);

        absenceRegistrationService.deleteLeaveRequest(leaveRequest.id as number).then((isDeleted: boolean) => {
            if (isDeleted) {
                toast.success("Uw aanvraag is verwijderd!");
                goBackToAbsenceRegistrationCalender();
            }
        }).catch(() => {

        }).finally(() => {
            setIsLoading(false);
        });
    }

    function getUpdateConfirmationDialog() {
        return (
            <Dialog
                open={isUpdateConfimationDialogOpen}
                onClose={() => setIsUpdateConfimationDialogOpen(false)}>
                <DialogTitle>Bevestiging</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bent u zeker dat u deze aanvraag wil wijzigen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: "#2F5FA1" }}>
                    <Button onClick={() => setIsUpdateConfimationDialogOpen(false)} color="inherit">
                        Annuleer
                    </Button>
                    <Button onClick={updateLeaveRequest} color="inherit" autoFocus>
                        Wijzig
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function getRemoveConfirmationDialog() {
        return (
            <Dialog
                open={isRemoveConfimationDialogOpen}
                onClose={() => setIsRemoveConfimationDialogOpen(false)}>
                <DialogTitle>Verwijderen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bent u zeker dat u deze aanvraag wil verwijderen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: "#2F5FA1" }}>
                    <Button onClick={() => setIsRemoveConfimationDialogOpen(false)} color="inherit">
                        Annuleer
                    </Button>
                    <Button onClick={removeLeaveRequest} color="inherit" autoFocus>
                        Verwijder
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function selectBalanceCounter(balanceCounter: BalanceCounter) {
        setLeaveTypeFilter(balanceCounter.type);

        const filteredLeaveCodes = leaveCodes.filter(r => r.leaveTypeCode === balanceCounter.type);
        if (filteredLeaveCodes.length === 1) {
            setLeaveRequestCode(filteredLeaveCodes[0].code);
        }
        else if (filteredLeaveCodes.length > 1) {
            const typeHasCode = filteredLeaveCodes.filter(r => r.code === leaveRequestCode);

            if (typeHasCode.length === 0) {
                setLeaveRequestCode("");
            }
        }
    }

    function getLeaveRequestDiv() {

        if (!leaveRequest || !leaveCodes)
            return (<></>);

        const timeFieldsReadOnly = period === LeaveRequestPeriod.DayPart1 || period === LeaveRequestPeriod.DayPart2;

        let filteredLeaveCodes = leaveCodes;

        if (leaveCodes.findIndex(lc => lc.code === leaveRequest.code) === -1) {
            filteredLeaveCodes.push({ code: leaveRequest.code, name: leaveRequest.leaveCodeName ?? "", leaveTypeCode: "" })
        }

        if (leaveTypeFilter !== undefined) {
            filteredLeaveCodes = filteredLeaveCodes.filter(r => r.leaveTypeCode === leaveTypeFilter);
        }

        const handleChange = (event: SelectChangeEvent<string>) => {
            const selectedValue = event.target.value as string;

            setLeaveRequestCode(selectedValue);
        };

        return (
            <Stack spacing={3} style={{ height: "100%" }} textAlign="start">
                <LoadSpinner top={"65px"} isLoading={isLoading} />
                <FormControl fullWidth className="mt-2">
                    <InputLabel required disabled={!isInEditMode}>Type</InputLabel>
                    <Select
                        label="Type"
                        disabled={!isInEditMode}
                        value={`${leaveRequestCode}`}
                        onChange={handleChange}
                    >
                        {filteredLeaveCodes
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((leaveCode) => (
                                <MenuItem key={leaveCode.code} value={`${leaveCode.code}`}>
                                    {leaveCode.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel disabled>Status</InputLabel>
                    <Select
                        label="Status"
                        disabled
                        value={leaveRequest.status}>
                        <MenuItem value={LeaveRequestStatus.Pending}>{translateLeaveRequestStatus(LeaveRequestStatus.Pending)}</MenuItem>
                        <MenuItem value={LeaveRequestStatus.Accepted}>{translateLeaveRequestStatus(LeaveRequestStatus.Accepted)}</MenuItem>
                        {leaveRequest.status === LeaveRequestStatus.Collective ? <MenuItem value={LeaveRequestStatus.Collective}>{translateLeaveRequestStatus(LeaveRequestStatus.Collective)}</MenuItem> : null}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel disabled={!isInEditMode}>Periode</InputLabel>
                    <Select
                        label="Periode"
                        disabled={!isInEditMode}
                        value={period}
                        onChange={(event: any) => {
                            setPreviousPeriod(period);
                            setPeriod(event.target.value);
                        }}
                    >
                        <MenuItem value={LeaveRequestPeriod.WholeDay}>{translateLeaveRequestPeriod(LeaveRequestPeriod.WholeDay)}</MenuItem>
                        {currentWeeksSchedule && selectedDay != null && currentWeeksSchedule.week1Days[selectedDay].hasAmTime &&
                            <MenuItem value={LeaveRequestPeriod.DayPart1}>{translateLeaveRequestPeriod(LeaveRequestPeriod.DayPart1)}</MenuItem>
                        }
                        {currentWeeksSchedule && selectedDay != null && currentWeeksSchedule.week1Days[selectedDay].hasPmTime &&
                            <MenuItem value={LeaveRequestPeriod.DayPart2}>{translateLeaveRequestPeriod(LeaveRequestPeriod.DayPart2)}</MenuItem>
                        }
                        <MenuItem value={LeaveRequestPeriod.Hours}>{translateLeaveRequestPeriod(LeaveRequestPeriod.Hours)}</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nl">
                    <>
                        <Row>
                            <Col xs={6} className="p-0 pe-1">
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} fullWidth required />}
                                    minDate={today}
                                    label="Start"
                                    disabled={!isInEditMode}
                                    value={leaveRequestStart}
                                    onChange={(newValue) => {
                                        handleOnLeaveRequestStartChange(dayjs(newValue).toDate());
                                    }}
                                />
                            </Col>
                            <Col xs={6} className="p-0 ps-1">
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} fullWidth required />}
                                    minDate={today}
                                    label="Einde"
                                    disabled={!isInEditMode}
                                    value={leaveRequestEnd}
                                    onChange={(newValue) => {
                                        setLeaveRequestEnd(dayjs(newValue).toDate());
                                    }}
                                />
                            </Col>
                        </Row>

                        {(period === LeaveRequestPeriod.Hours || period === LeaveRequestPeriod.DayPart1 || period === LeaveRequestPeriod.DayPart2) &&
                            <Row className="mt-2">
                                <Col xs={6} className="p-0 pe-1">
                                    <ScrollableTimePicker
                                        value={startTime}
                                        daySchedule={daySchedule}
                                        readOnly={timeFieldsReadOnly || leaveRequest.isSendToPrisma || !isInEditMode}
                                        period={period}
                                        previousPeriod={previousPeriod}
                                        handleUpdate={(value: Date) => handleStartTimeChange(value)}
                                    />
                                </Col>
                                <Col xs={6} className="p-0 ps-1">
                                    <ScrollableTimePicker
                                        value={endTime}
                                        daySchedule={daySchedule}
                                        readOnly={timeFieldsReadOnly || leaveRequest.isSendToPrisma || !isInEditMode}
                                        period={period}
                                        previousPeriod={previousPeriod}
                                        handleUpdate={(value: Date) => handleEndTimeChange(value)}
                                    />
                                </Col>
                            </Row>
                        }
                    </>

                    {period !== LeaveRequestPeriod.WholeDay &&
                        <FormControl fullWidth>
                            <TextField
                                value={amountOfHours}
                                id="outlined-number"
                                label="Aantal uren"
                                type="text"
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    }
                </LocalizationProvider>
                {!isEditable &&
                    <Alert severity="warning">Deze verlofaanvraag is niet meer wijzigbaar.</Alert>
                }

                {getBalanceCharts()}
            </Stack>
        );
    }

    function getBalanceCharts() {
        return (
            <div className="mt-4" style={{ paddingBottom: 40 }}>
                <Typography variant="subtitle1" fontWeight="bold" className="mb-1">
                    Saldo
                </Typography>

                <LoadSpinner top={"20px"} isLoading={isBalanceCountersLoading} />

                {balanceCounters &&
                    <Row className="m-0 p-0 row flex-row mb-5">
                        {balanceCounters.length > 0 ?
                            balanceCounters.map((balanceCounter: BalanceCounter) => {
                                return (
                                    <Col xs={6} md={4} key={balanceCounter.id}>
                                        <DonutChartCard
                                            text={balanceCounter.description ?? balanceCounter.typeName}
                                            hoursLeft={balanceCounter.hoursLeft}
                                            totalHours={balanceCounter.totalHours}
                                            color={balanceCounter.colorCode}
                                            isSelected={balanceCounter.type === leaveTypeFilter}
                                            onClickFunction={() => !leaveRequest.isSendToPrisma && isInEditMode && selectBalanceCounter(balanceCounter)}
                                        />
                                    </Col>
                                );
                            })
                            :
                            <Alert severity="error">Geen saldotellers gevonden.</Alert>
                        }
                    </Row>
                }
            </div>
        );
    }

    function getBottomButtonsDiv() {
        if (!leaveRequest || !isEditable)
            return (<></>);

        const isAccepted = leaveRequest.status === LeaveRequestStatus.Accepted || leaveRequest.status === LeaveRequestStatus.Collective;

        return (
            <div style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white",
                zIndex: 1,
                display: "flex",
                justifyContent: "space-evenly",
            }}>

                {!isAccepted &&

                    <IconButton color="inherit" className="background-color-red" style={{ width: 60, height: 60, margin: 12 }} aria-label="Verwijderen"
                        disabled={isLoading || isBalanceCountersLoading || leaveRequest.isSendToPrisma as boolean}
                        onClick={() => !isLoading && !leaveRequest.isSendToPrisma && setIsRemoveConfimationDialogOpen(true)}>
                        <DeleteOutlineIcon color="inherit" style={{ fontSize: 40 }} />
                    </IconButton>

                }
                {isInEditMode ?
                    <IconButton color="inherit" className="color-green" aria-label="Bewaar"
                        disabled={isLoading || isBalanceCountersLoading || leaveRequest.isSendToPrisma as boolean}
                        onClick={() => !isLoading && !leaveRequest.isSendToPrisma && checkIfFormIsValidAndOpenUpdateDialog()}
                        style={{ maxHeight: 60, maxWidth: 60, margin: 12 }}>
                        <CheckCircleIcon color="inherit" style={{ fontSize: 70 }} />
                    </IconButton>
                    :
                    <IconButton color="inherit" className="background-color-blue" style={{ width: 60, height: 60, margin: 12 }} aria-label="Wijzig"
                        disabled={isLoading || isBalanceCountersLoading || leaveRequest.isSendToPrisma as boolean}
                        onClick={() => !leaveRequest.isSendToPrisma && setIsInEditMode(true)}>
                        <EditIcon color="inherit" style={{ fontSize: 40 }} />
                    </IconButton>
                }

            </div>
        );
    }

    function getHeader() {
        return (
            <div>
                <AppBar position="static" elevation={0} className="background-color-blue" style={{ height: "51px" }}>
                    <Toolbar>
                        <Typography variant="h6" sx={{ flexGrow: 1 }} textAlign="start" color="inherit">
                            Detail verlofaanvraag
                        </Typography>
                        <IconButton color="inherit" edge="end" onClick={goBackToAbsenceRegistrationCalender} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    return (
        <>
            {getHeader()}
            {getUpdateConfirmationDialog()}
            {getRemoveConfirmationDialog()}

            <div
                className="m-0 pt-3 ps-3 pe-3"
                style={{ height: `calc(${windowHeight}px - 54px)`, overflowY: "auto", overflowX: "hidden", maxHeight: "-webkit-fill-available" }}
            >
                {getLeaveRequestDiv()}
            </div>

            {getBottomButtonsDiv()}
        </>
    );
}
import { Col, Row } from "reactstrap";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type Props = {
    title: string;
    returnFunction: Function;
};

export default function HeaderWithReturnFunction(props: Props) {
    function getHeader() {
        return (
            <header className="simple-header" style={{ width: "100%" }}>
                <Row>
                    <Col xs={3} style={{ textAlign: "start" }}>
                        <IconButton
                            className="ms-1"
                            color="inherit"
                            onClick={() => props.returnFunction()}
                            aria-label="Terug"
                        >
                            <ArrowBackIcon color="inherit" fontSize="large" />
                        </IconButton>
                    </Col>
                    <Col xs={9} style={{ textAlign: "end", alignSelf: "center" }}>
                        <Typography component="div" variant="h6" paddingX={2}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}>
                            {props.title}
                        </Typography>
                    </Col>
                </Row>
            </header>
        );
    }

    return <>
        {getHeader()}
    </>;
}

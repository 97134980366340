export enum ContractType {
    OpenEndedContract,
    FixedTermContract,
    ReplacementContract,
    CertainWork
}

export function translateContractTypeEnumValue(type: ContractType): string {
    switch(type) { 
        case ContractType.OpenEndedContract: {
           return "Onbepaalde duur";
        } 
        case ContractType.FixedTermContract: {
            return "Bepaalde duur";
        } 
        case ContractType.ReplacementContract: {
            return "Vervangingscontract";
        } 
        case ContractType.CertainWork: {
            return "Bepaald werk";
        } 
        default: { 
            return "";
        } 
    } 
}
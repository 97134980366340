import moment from "moment";
import { convertHoursDecimalToTimeString } from "../../../Helpers/DateHelper";
import { LeaveRequestPeriod, translateLeaveRequestPeriod } from "../../../Models/Enums/LeaveRequestPeriod";
import { LeaveRequestStatus, translateLeaveRequestStatus } from "../../../Models/Enums/LeaveRequestStatus";
import { LeaveRequest } from "../../../Models/LeaveRequest";
import { Card, CardActionArea, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import { Col, Label, Row } from "reactstrap";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    leaveRequest: LeaveRequest;
    goToDetailsLeaveRequestPage: Function;
    closeSelectedLeaveRequest: Function;
}

function LeaveRequestCard(props: Props) {
    const { leaveRequest } = props;
    const backgroundStyle = props.leaveRequest.status === LeaveRequestStatus.Pending ?
        "repeating-linear-gradient(-45deg, transparent, transparent 2px, #9990 2px, white 6px)" : "";

    return (
        <Card className="mt-0" color="primary" style={{ width: "100%" }} sx={{ boxShadow: 4 }}>
            <CardActionArea>
                <CardHeader
                    action={
                        <IconButton color="inherit" onClick={() => props.closeSelectedLeaveRequest()} aria-label="Close">
                            <CloseIcon fontSize="medium" />
                        </IconButton>
                    }
                    title={
                        <Typography gutterBottom variant="h6" fontWeight="bold" component="div" display="inline-block" margin={0}
                            onClick={() => props.goToDetailsLeaveRequestPage()}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}>
                            <div style={{
                                width: 7, height: 20, borderRadius: 2.5, display: "inline-block",
                                marginRight: 7.5, top: "2px", position: "relative",
                                backgroundImage: backgroundStyle,
                                backgroundColor: leaveRequest.colorCode
                            }}></div>
                            {leaveRequest.leaveCodeName}
                        </Typography>
                    }
                    subheader={
                        <Typography gutterBottom variant="subtitle1" component="div" margin={0}
                            onClick={() => props.goToDetailsLeaveRequestPage(leaveRequest)}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                            }}>
                            {translateLeaveRequestStatus(leaveRequest.status)}
                        </Typography>
                    }
                    className="pb-0">
                </CardHeader>
                <CardContent className="p-0 p-3 pt-2 pb-2" onClick={() => props.goToDetailsLeaveRequestPage()}>
                    <Row>
                        <Col xs={8}>
                            <Label className="m-0">
                                {moment(leaveRequest.start).format("LL")}
                            </Label>
                        </Col>
                        <Col xs={4} style={{ textAlign: "end" }}>
                            <Label className="m-0 me-2">
                                {leaveRequest.period === LeaveRequestPeriod.WholeDay ?
                                    translateLeaveRequestPeriod(leaveRequest.period)
                                    :
                                    `${convertHoursDecimalToTimeString(leaveRequest.totalHours as number)}`
                                }
                            </Label>
                        </Col>
                    </Row>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default LeaveRequestCard;
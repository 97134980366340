import { createSlice } from '@reduxjs/toolkit'

export const drawerSlicer = createSlice({
    name: 'drawer',
    initialState: {
      isOpen: false,
    },
    reducers: {
      setVisibility: (state, action) => {
        state.isOpen = action.payload;
      },
      toggle: (state) => {
        state.isOpen = !state.isOpen;
      }
    },
  })
  
  export const drawerActions = drawerSlicer.actions
  
  export default drawerSlicer.reducer
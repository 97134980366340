import { useCallback, useEffect, useMemo, useState } from "react";
import { Row } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { LeaveRequest } from "../../Models/LeaveRequest";
import AbsenceRegistrationService from "../../Services/AbsenceRegistrationService";
import { LoadSpinner } from "../../Components/LoadSpinner";
import LeaveRequestsTable from "../../Components/AbsenceRegistrationTool/LeaveRequestReports/LeaveRequestsTable";
import useWindowHeight from "../../hooks/useWindowHeight";
import HeaderWithReturnFunction from "../../Components/HeaderWithReturnFunction";
import SwipeableViews from "react-swipeable-views";
import { LeaveRequestReportsByYears } from "../../Models/LeaveRequestReportsByYears";
import StatusLegendCard from "../../Components/AbsenceRegistrationTool/LeaveRequestReports/StatusLegendCard";

enum YearTabs {
    PreviousYear = 0,
    CurrentYear,
    NextYear,
}

export default function LeaveRequestReports() {
    const currentYear = new Date().getFullYear();
    const history = useHistory();
    const location = useLocation();
    const { instance } = useMsal();

    const states = location.state as any;
    const goToDate = states.goToDate as Date;
    const calenderDate = states.calenderDate as Date;
    const typeDescription = states.typeDescription as string;
    const type = states.type as string;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [leaveRequestReports, setLeaveRequestReports] = useState<LeaveRequestReportsByYears>();
    const [selectedTab, setSelectedTab] = useState<YearTabs>(getSelectedTabOnInit());

    const { windowHeight } = useWindowHeight();
    const absenceRegistrationService = useMemo(() => new AbsenceRegistrationService(instance), [instance]);

    const getLeaveRequestReports = useCallback(
        (year: number, type: string) => {
            setIsLoading(true);

            absenceRegistrationService
                .getLeaveRequestsByStatus(year, type)
                .then((values: LeaveRequestReportsByYears) => {
                    setLeaveRequestReports(values);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [absenceRegistrationService]
    );

    function goBackToBalanceCounters(): void {
        history.push({
            pathname: "/BalanceCounters",
            state: {
                goToDate: goToDate,
                calenderDate: calenderDate,
            },
        });
    }

    useEffect(() => {
        getLeaveRequestReports(currentYear, type);
    }, [currentYear, type, getLeaveRequestReports]);

    function getSelectedTabOnInit(): YearTabs {
        if (goToDate.getFullYear() <= currentYear - 1) return YearTabs.PreviousYear;

        if (goToDate.getFullYear() >= currentYear + 1) return YearTabs.NextYear;

        return YearTabs.CurrentYear;
    }

    function getLeaveRequests(
        leaverequests: LeaveRequest[] | undefined,
        title: string,
        year: number
    ) {
        if (!leaverequests || isLoading) return <></>;

        return (
            <Row
                className="m-0 p-0 row flex-row mb-3"
                style={{ width: "100%", overflowX: "hidden" }}
            >
                <LeaveRequestsTable
                    leaveRequests={leaverequests}
                    title={title}
                    year={year}
                />
            </Row>
        );
    }

    function getBottomYearsTabs() {
        if (selectedTab == null) return;

        return (
            <div className="fixed-bottom" style={{ color: "var(--blue-color)" }}>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    onChange={(_event, value) => setSelectedTab(value)}
                >
                    <Tab label={currentYear - 1} value={YearTabs.PreviousYear} />
                    <Tab label={currentYear} value={YearTabs.CurrentYear} />
                    <Tab label={currentYear + 1} value={YearTabs.NextYear} />
                </Tabs>
            </div>
        );
    }

    function getTabsViews() {
        return (
            <SwipeableViews
                axis={"x"}
                index={selectedTab}
                onChangeIndex={(index) => setSelectedTab(index)}
            >
                <div
                    className="pt-3 ps-3 pe-3"
                    style={{
                        height: `calc(${windowHeight}px - 169px)`,
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "-webkit-fill-available",
                    }}
                >
                    {getLeaveRequests(
                        leaveRequestReports?.previousYearHistory,
                        "Opgenomen verlof",
                        currentYear - 1
                    )}

                    {getLeaveRequests(
                        leaveRequestReports?.previousYearFuture,
                        "Gepland verlof",
                        currentYear - 1
                    )}
                </div>
                <div
                    className="pt-3 ps-3 pe-3"
                    style={{
                        height: `calc(${windowHeight}px - 169px)`,
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "-webkit-fill-available",
                    }}
                >
                    {getLeaveRequests(
                        leaveRequestReports?.currentYearHistory,
                        "Opgenomen verlof",
                        currentYear
                    )}
                    {getLeaveRequests(
                        leaveRequestReports?.currentYearFuture,
                        "Gepland verlof",
                        currentYear
                    )}
                </div>
                <div
                    className="pt-3 ps-3 pe-3"
                    style={{
                        height: `calc(${windowHeight}px - 169px)`,
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "-webkit-fill-available",
                    }}
                >
                    {getLeaveRequests(
                        leaveRequestReports?.nextYearHistory,
                        "Opgenomen verlof",
                        currentYear + 1
                    )}
                    {getLeaveRequests(
                        leaveRequestReports?.nextYearFuture,
                        "Gepland verlof",
                        currentYear + 1
                    )}
                </div>
            </SwipeableViews>
        );
    }

    return (
        <>
            <HeaderWithReturnFunction title={typeDescription} returnFunction={goBackToBalanceCounters} />

            <div className="m-0">
                <LoadSpinner top={"65px"} isLoading={isLoading} />

                <div className="pt-3 ps-3 pe-3">
                    <StatusLegendCard />
                </div>

                {getTabsViews()}
            </div>

            {getBottomYearsTabs()}
        </>
    );
}
